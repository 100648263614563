import React, { useMemo, useState } from "react"
import { IconButton, Box, Typography } from "@material-ui/core"
import { Edit } from "@material-ui/icons"
import { FTLButton } from "../../components"
import { FTLTable } from "../../components/ui/Table/FTLTable"

export const PushServicesPage = () => {
  const [stubNewService, setStubNewService] = useState(false)
  const columns = useMemo(() => {
    return [
      {
        Header: "Наименование",
        accessor: "title",
        disableSortBy: true,
      },
      {
        Header: "",
        accessor: "action",
        disableSortBy: true,
      },
    ]
  }, [])
  const data = useMemo(() => {
    return [
      {
        title: "Push сервис 1",
        action: (
          <IconButton>
            <Edit />
          </IconButton>
        ),
      },
      {
        title: "Push сервис 2",
        action: (
          <IconButton>
            <Edit />
          </IconButton>
        ),
      },
      {
        title: "Push сервис 3",
        action: (
          <IconButton>
            <Edit />
          </IconButton>
        ),
      },
    ]
  }, [])

  // const actions = useMemo(() => {
  //   return [
  //     {
  //       IconComponent: Edit,
  //     },
  //   ]
  // }, [])

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h1">Параметры Push-сервисов</Typography>
        <FTLButton
          color="primary"
          onClick={() => setStubNewService(true)}
          //   onClick={() => {
          //     handleNewServiceBtn({ to: "/new-service", label: "Новый сервис" })
          //     history.push("/new-service") //!  FIX ME
          //   }}
        >
          Новый сервис
        </FTLButton>
      </Box>
      {stubNewService ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="calc(100% - 56px)"
        >
          <Typography variant="h2" color="secondary">
            Нет данных для отображения
          </Typography>
        </Box>
      ) : (
        <Box mt={4}>
          <FTLTable pageCount={1} columns={columns} data={data} />
        </Box>
      )}
    </>
  )
}
