import React, { useEffect, useRef, useState, useLayoutEffect } from "react"

import {
  Tabs,
  Tab,
  Container,
  makeStyles,
  Divider,
  Box,
  Typography,
} from "@material-ui/core"

import { PersonalDataTab } from "../layouts/Profile/PersonalDataTab"
import { ContactDataTab } from "../layouts/Profile/ContactDataTab"
import { SecurityTab } from "../layouts/Profile/SecurityTab"
import { useLocation } from "react-router-dom"
import { FTLSnackbar } from "../components/ui/FTLSnackbar"
import { typography, palette } from "../shared/theme"
import { FTLToolbar } from "../components/ui/FTLToolbar"

const CustomTabIndicator = () => <h1>hello</h1>

export const ProfilePage = () => {
  const location = useLocation<{ tabIndex?: number }>()
  const [tabIndex, setTabIndex] = React.useState(location.state?.tabIndex ?? 0)
  const classes = useStyles()
  const tabIndicatorRef = useRef<null | HTMLDivElement>(null)
  const tabIndicatorRefs = useRef<[] | HTMLDivElement[]>(null)
  const [tabIndicatorWidth, setTabIndicatorWidth] = useState(0)
  const [tabIndicatorleft, setTabIndicatorLeft] = useState(0)
  const [tabIndicatorStyle, setTabIndicatorStyle] = useState<{
    left?: number
    width: number
  }>({
    left: 0,
    width: 0,
  })
  // const customIndicatorRef = useRef<HTMLDivElement | null>(null)
  // console.log(customIndicatorRef)

  useEffect(() => {
    if (typeof location.state?.tabIndex === "number") {
      setTabIndex(location.state.tabIndex)
    }
  }, [location.state])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue)
  }

  useEffect(() => {
    // const id = setTimeout(() => {
    const tabsContainer =
      tabIndicatorRef.current?.firstElementChild?.firstElementChild
    const tab = tabsContainer?.children[tabIndex]
    if (tab?.firstElementChild) {
      setTabIndicatorWidth(tab.firstElementChild.clientWidth)
    }
    // }, 0)

    // return () => clearTimeout(id)
  }, [tabIndicatorRef, tabIndex])
  return (
    // <Container maxWidth="md">
    <>
      <Typography variant="h1">Профиль</Typography>
      <Box position="relative" mt={6}>
        <Tabs
          classes={{
            root: classes.tabsRoot,
            indicator: classes.tabIndicator,
            flexContainer: classes.tabsContainer,
          }}
          TabIndicatorProps={{
            // style: {
            //   zIndex: 1,
            // },
            style: tabIndicatorWidth
              ? {
                  zIndex: 1,
                  clip: `rect(0px, ${
                    tabIndex > 0
                      ? `${tabIndicatorWidth + 12}px`
                      : `${tabIndicatorWidth}px`
                  }, 2px, ${tabIndex > 0 ? "12px" : "0"})`,
                  // left: `${tabIndicatorStyle.left + 12}px`,
                  // width: `${tabIndicatorWidth}px`,
                  // left: `${tabIndicatorStyle.left}px`,
                  // left: `${tabIndicatorWidth - 15}px`,
                }
              : { zIndex: 1 },
          }}
          innerRef={tabIndicatorRef}
          value={tabIndex}
          indicatorColor="primary"
          onChange={handleChange}
        >
          <Tab
            classes={{
              root: classes.tab,
              selected: classes.selectedTab,
              wrapper: classes.tabText,
            }}
            style={{ padding: "0 12px 0 0" }}
            label="Личные данные"
          />
          <Tab
            classes={{
              root: classes.tab,
              selected: classes.selectedTab,
              wrapper: classes.tabText,
            }}
            // ref={activeTabRef}
            label="Контактные данные "
          />
          <Tab
            classes={{
              root: classes.tab,
              selected: classes.selectedTab,
              wrapper: classes.tabText,
            }}
            // ref={activeTabRef}
            label="Безопасность"
          />
        </Tabs>
        {/* <CustomTabIndicator */}
        {/* <Divider absolute style={{ height: "2px", background: "red" }} innerRef={customIndicatorRef} /> */}
        <Divider
          absolute
          style={{ height: "2px", backgroundColor: "#E3E5E8" }}
        />
      </Box>
      {tabIndex === 0 && <PersonalDataTab />}
      {tabIndex === 1 && <ContactDataTab />}
      {tabIndex === 2 && <SecurityTab />}
      <FTLToolbar
        currentTabIndex={tabIndex}
        length={2}
        position="absolute"
        goToPrevTab={() => setTabIndex(tabIndex - 1)}
        goToNextTab={() => setTabIndex(tabIndex + 1)}
      />
      {/* <FTLSnackbar
        icon={<Error />}
        open
        message="Неверный пароль Неверный пароль"
      /> */}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  formContainer: {
    // marginTop: `${theme.spacing() * 6}px`,
  },
  tabsRoot: { minHeight: "32px" },
  tabsContainer: {
    "& button": {
      whiteSpace: "nowrap",
    },
  },
  tab: {
    color: palette.tabs.enabled,
    minHeight: "32px",
    padding: "6px 12px",
    // "&:not(:first-child)": {
    //   padding: "6px 12px",
    // },
  },
  tabText: {
    textTransform: "none",
    // padding: "6px 12px",
    // "&:first-child": {
    //   padding: 0,
    // },
    ...typography.unsupportedSemanticTags.buttonTextSecondary,
  },
  selectedTab: {
    color: theme.palette.primary.main,
  },
  // tabText: { color: theme.palette.text.primary },
  tabIndicator: {
    borderRadius: "2px",
    // backgroundColor: colors.B400,
    // width: "100% !important",
    // left: "0 !important",
    // background: (tabIndex) => {
    //   return `linear-gradient(to right, red ${
    //     (tabIndex as number) * 160
    //   }px , black 50%)`
    // },
    // color: theme.palette.text.,
  },
}))
