import React from "react"
import { Box } from "@material-ui/core"
import { FTLButton } from "./FTLButton"

type Props = {
  currentTabIndex?: number
  position?: string
  // отсчёт с нуля, т.к. длина массив
  length?: number
  onSave?: (tabIndex: number) => void
  onCancel?: (tabIndex: number) => void
  goToPrevTab?: () => void
  goToNextTab?: () => void
  onSaveMessage?: string
  onSaveBtnId?: string
  isValid?: boolean
  hasCancel?: boolean
  async?: boolean
  children?: React.ReactNode
}

export const FTLToolbar = ({
  currentTabIndex,
  length,
  hasCancel,
  children,
  position = "sticky",
  onSaveMessage = "Сохранить",
  isValid,
  onSaveBtnId,
  async,
  goToNextTab = () => {},
  goToPrevTab = () => {},
}: Props) => {
  // const goToPrevTab = (tabIndex?: number) => {

  // }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      position={position}
      bottom="0"
      width="100%"
      maxWidth="920px"
      // width="920px"
      height="56px"
      alignItems="center"
      mt={6}
      style={{ background: "#fff", zIndex: 1, borderTop: "2px solid #E3E5E8" }}
    >
      <Box display="flex">
        <Box>
          <FTLButton
            form={onSaveBtnId}
            async={async}
            type="submit"
            color="primary"
          >
            {onSaveMessage}
          </FTLButton>
        </Box>
        {hasCancel && (
          <Box ml={2}>
            <FTLButton variant="text">Отмена</FTLButton>
          </Box>
        )}
        {children}
      </Box>
      {typeof currentTabIndex === "number" && (
        <Box display="flex">
          <Box>
            <FTLButton
              color="secondary"
              onClick={() => currentTabIndex !== 0 && goToPrevTab()}
            >
              Назад
            </FTLButton>
          </Box>
          <Box ml={2}>
            <FTLButton
              color="secondary"
              onClick={() =>
                currentTabIndex !== (length ?? 0 + 1) && goToNextTab()
              }
            >
              Далее
            </FTLButton>
          </Box>
        </Box>
      )}
    </Box>
  )
}
