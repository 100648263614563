import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
} from "react"
import {
  Drawer,
  List,
  ListItem as MUIListItem,
  makeStyles,
  Divider,
  Button,
  Box,
  SvgIconProps,
  ListItemIcon,
  ListItemText,
  LinkProps,
  Icon,
  IconButton,
  Paper,
  Modal,
  Popover,
  Popper,
  Fade,
  Backdrop,
} from "@material-ui/core"
import { typography } from "../shared/theme"
import { NavLink, MemoryRouter } from "react-router-dom"
import {
  ArrowLeft,
  ArrowRight,
  KeyboardArrowRight,
  KeyboardArrowLeft,
} from "@material-ui/icons"

type Props = {
  items: MenuItem[]
}

type MenuItem = {
  label: string
  icon: React.ReactElement<SvgIconProps>
  items?: NestedMenuItem[]
  linkTo?: string
}

type NestedMenuItem = {
  linkTo: string
  label: string
}

function NestedListItemLink({
  label,
  // icon,
  onClick,
  to,
}: { to: string; onClick: () => void } & Omit<MenuItem, "linkTo" | "icon">) {
  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<LinkProps, "to">>((itemProps, ref) => (
        <NavLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  )
  const classes = useStyles()

  return (
    <MUIListItem button component={renderLink} onClick={onClick}>
      {/* <ListItemIcon>{icon}</ListItemIcon> */}
      <ListItemText
        className={classes.nestedNavItemText}
        primary={label}
      ></ListItemText>
    </MUIListItem>
  )
}

function ListItem({
  // label,
  // icon,
  children,
  onClick,
  to,
  title,
  hasSubItems,
  isMinimized,
}: {
  to?: string
  onClick?: () => void
  title?: string
  children?: React.ReactNode
  isMinimized?: boolean
  hasSubItems?: boolean
}) {
  const classes = useStyles()
  const [isHover, setIsHover] = useState(false)
  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<LinkProps, "to">>((itemProps, ref) => (
        <NavLink
          activeStyle={{ backgroundColor: " rgba(3, 11, 22, 0.08)" }}
          to={to!}
          // isActive={(match, { pathname }) => {
          //   return pathname.split('')[0].indexOf(to!) > -1
          // }}
          ref={ref}
          {...itemProps}
        />
      )),
    [to]
  )

  return (
    <MUIListItem
      classes={{ root: classes.listItem }}
      className={isMinimized ? classes.listItemMinimized : ""}
      button
      // isMinimized={isMinimized}
      component={to ? renderLink : "li"}
      onMouseEnter={(e: any) => {
        setIsHover(!isHover)
      }}
      onMouseLeave={() => setIsHover(!isHover)}
      onClick={onClick}
    >
      {children}
      {hasSubItems && !isMinimized && isHover && (
        <KeyboardArrowRight style={{ width: "24px", height: "24px" }} />
      )}
      {/* <Tooltip wait={300} title={title} /> */}
      {isHover && isMinimized && <Tooltip wait={300} title={title} />}
    </MUIListItem>
  )
}

type TooltipProps = {
  wait: number
  title?: string
}

const Tooltip = ({ wait, title }: TooltipProps) => {
  const [isShow, setIsShow] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()
  useEffect(() => {
    const id = setTimeout(() => {
      setIsShow(true)
    }, wait)
    return () => clearTimeout(id)
  }, [wait])
  return isShow ? <div className={classes.popover}>{title}</div> : null
}

export const SideMenu = ({ items }: Props) => {
  const classes = useStyles()
  const [isMinimized, setIsMinimized] = useState(false)
  const [nestedListModalIsOpen, setNestedListModalIsOpen] = useState(false)
  const [nestedOpenItems, setNestedOpenItems] = useState<NestedMenuItem[]>([])
  const [drawerDOMState, setDrawerDOMState] = useState({
    width: 0,
    height: 0,
    top: 0,
  })
  const drawerListRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const listNode = drawerListRef.current?.firstElementChild

    if (listNode) {
      setDrawerDOMState({
        width: isMinimized ? 64 : 245,
        height: listNode?.getBoundingClientRect().height,
        top: listNode?.getBoundingClientRect().top,
      })
    }
  }, [isMinimized])

  const handleOpenMenuItem = (items: NestedMenuItem[]) => {
    setNestedListModalIsOpen(true)
    setNestedOpenItems(items)
  }

  const handleCloseMenuItem = () => {
    setNestedListModalIsOpen(false)
    setNestedOpenItems([])
  }

  const handleDrawIsCollapsed = () => {
    // if (!isMinimized) {
    // setDraweListDiv(drawerRef.current?.firstElementChild as HTMLDivElement)
    // }
    setIsMinimized(!isMinimized)
  }
  return (
    <>
      <Drawer
        variant="permanent"
        ref={drawerListRef}
        classes={{
          paper: `${classes.paper} ${
            isMinimized ? classes.drawIsMinimized : classes.drawIsOpen
          }`,
        }}
      >
        {/* <MemoryRouter
          // initialEntries={items.map((i) => i.linkTo)}
          initialIndex={0}
        > */}
        {items.length > 0 && (
          <List className={classes.list} component="nav">
            {items.map((item, index) => (
              <ListItem
                key={index}
                to={item.linkTo}
                title={item.label}
                isMinimized={isMinimized}
                hasSubItems={Boolean(item?.items)}
                // onHover={() => {}}
                onClick={() => {
                  if (item.items) {
                    handleOpenMenuItem(item.items)
                  }
                }}
              >
                <ListItemIcon color="primary" className={classes.listItemIcon}>
                  {item.icon}
                </ListItemIcon>
                {!isMinimized && (
                  <ListItemText
                    className={classes.listItemText}
                    primary={item.label}
                  ></ListItemText>
                )}
              </ListItem>
            ))}
          </List>
        )}
        {/* </MemoryRouter> */}
        <Box style={{ marginTop: "auto" }}>
          <Divider style={{ backgroundColor: "#EBEDF0" }} />
          <Box margin="4px 12px">
            {isMinimized ? (
              <IconButton
                style={{ borderRadius: "initial" }}
                className={classes.shevronIcon}
                onClick={() => {
                  setIsMinimized(false)
                  // console.log(drawerWidth)
                }}
              >
                <KeyboardArrowRight />
              </IconButton>
            ) : (
              <Button
                // fullWidth
                // style={{ textTransform: "none", marginTop: "12px" }}
                // style={{shevronButton}}
                className={classes.shevronButton}
                startIcon={<KeyboardArrowLeft />}
                onClick={() => {
                  setIsMinimized(true)
                  setNestedListModalIsOpen(false)
                  // console.log(dra)
                }}
              >
                Свернуть
              </Button>
            )}
          </Box>
        </Box>
        <Modal
          open={nestedListModalIsOpen}
          onClose={handleCloseMenuItem}
          // closeAfterTransition
          // BackdropComponent={Backdrop}
          // BackdropProps={{
          //   timeout: 1000,
          // }}
        >
          <Fade timeout={350} in={nestedListModalIsOpen}>
            <Box
              style={{
                left: `${drawerDOMState.width - 5}px`,
                width: `${drawerDOMState.width}px`,
                height: `${drawerDOMState.height}px`,
                top: `${drawerDOMState.top}px`,
                outline: "none",
              }}
              className={classes.nestedList}
              component={Paper}
            >
              <List disablePadding>
                {nestedOpenItems.map((item, index) => (
                  <NestedListItemLink
                    onClick={handleCloseMenuItem}
                    key={index}
                    to={item.linkTo}
                    label={item.label}
                  />
                ))}
              </List>
            </Box>
          </Fade>
        </Modal>
      </Drawer>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#132339",
  },
  drawIsMinimized: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(10) + 24,
  },
  drawIsOpen: {
    width: "246px",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  paper: {
    backgroundColor: "#F3F4F6",
    overflowX: "hidden",
    // top: "56px",
    zIndex: 1301,
    height: "calc(100vh - 56px)",
    position: "relative",
    borderRight: "1px solid #EBEDF0",
  },
  list: {
    // padding: "20px 0",
    padding: "0",
    margin: "12px",
  },
  listItem: {
    display: "flex",
    justifyContent: "center",
    borderRadius: "4px",
    minWidth: "40px",
    // padding: (props: { isMinimized?: boolean }) =>
    //   props.isMinimized ? "8px" : "8px 12px",
    // padding: "10px",
    // color: theme.palette.text.primary,
    // paddingTop: "4px",
    // paddingBottom: "4px",
    // paddingLeft: "0",
    // paddingRight: "0",
    "&:hover": {
      backgroundColor: "rgba(3, 11, 22, 0.08)",
    },
  },
  listItemMinimized: {
    // padding: "10px",
    minHeight: "40px",
  },
  listItemText: {
    whiteSpace: "nowrap",
    marginTop: "0px",
    marginBottom: "0px",
    paddingLeft: "12px",
    "& > span": {
      ...typography.unsupportedSemanticTags.listItemNavText,
      // textOverflow: "ellipsis",
      // overflow: "hidden",
      maxWidth: "130px",
    },
  },
  listItemIcon: {
    minWidth: "initial",
    color: theme.palette.text.primary,
  },
  nestedList: {
    height: "100vh",
    position: "fixed",
    minWidth: "245px",
    boxShadow: "none",
    borderRadius: "0px",
    "& > ul": {
      margin: "12px",
      "& > li a": {
        padding: "10px 12px",
        borderRadius: "4px",
        "&:hover": {
          backgroundColor: "rgba(3, 11, 22, 0.08)",
        },
      },
    },
  },
  nestedNavItemText: {
    "& > span": {
      ...typography.unsupportedSemanticTags.listItemNavText,
    },
  },
  item: {
    color: "red",
    "& > a": {
      ...typography.unsupportedSemanticTags.buttonTextSecondary,
      textDecoration: "none",
      color: "#132339",
    },
    //  "&:not(:first-child)": { padding: "20px" }
  },

  itemIcon: {
    color: "#132339",
  },
  shevronButton: {
    textTransform: "none",
    padding: "10px 12px",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: "rgba(3, 11, 22, 0.08)",
    },
    ...typography.unsupportedSemanticTags.listItemNavText,
  },
  shevronIcon: {
    borderRadius: "4px",
    padding: "10px",
    "&:hover": {
      backgroundColor: "rgba(3, 11, 22, 0.08)",
    },
  },
  popover: {
    position: "fixed",
    left: "calc(64px + 8px)",
    backgroundColor: "#132339",
    padding: "2px 4px",
    borderRadius: "4px",
    color: "#fff",
    ...typography.unsupportedSemanticTags.tooltip,
  },
}))
