import React, { useReducer, useEffect, useState } from "react"
import NumberFormat from "react-number-format"
import { Box, Typography } from "@material-ui/core"
import { FTLTextField } from "../../components"
import { useLayout } from "../../shared/hooks/useLayout"
import { FTLSelect } from "../../components/ui/FTLSelect"
import { FTLToolbar } from "../../components/ui/FTLToolbar"
import { PhoneCombinedSelect } from "../../components/PhoneCombinedSelect"
import { createStore, combine, createEvent, sample, guard } from "effector"
import { FTLSelectAsync } from "../../components/ui/FTLSelectAsync"
import {
  getCities,
  getCountries,
  getServiceZones,
  createCourier,
} from "../../api/common"
import { Formik, Form, Field, useFormik, useField, FieldArray } from "formik"
import { FTLSnackbar } from "../../components/ui/FTLSnackbar"
import { VirtualizedSelectNew } from "../../components/ui/VirtualizedSelectNew"
import { ValueType } from "react-select"
import { FTLJsonLogger } from "../../components/ui/FTLLogger"
// import { history } from "../../App"
import { useHistory } from "react-router-dom"
import { CourierSchema, CourierState } from "./model"
import { FTLCheckbox } from "../../components/ui/FTLCheckbox"
import { typography } from "../../shared/theme"
// import { Action } from "../../types/useReducer"

type Props = {}

export const $firstName = createStore("")
export const $middleName = createStore("")
export const $lastName = createStore("")

export const formSubmitted = createEvent()
export const formDisabled = createEvent()
export const setField = createEvent()
//Временная заглушка
export const trigger = createEvent()

export const $form = combine({
  firstName: $firstName,
  middleName: $middleName,
  lastName: $lastName,
})
// .on(setField, (state, payload) => {})

// sample({
//   source: $form,
//   clock: trigger,
// })

const initState: CourierState = {
  firstName: "",
  middleName: "",
  lastName: "",
  phoneNumber: null,

  countryId: null,
  cityId: null,

  serviceId: null,
  externalSystems: [
    // SV
    // { externalSystemSyncAccountId: "5efb102dc8acc7466a4e4e73" },
    // BB
    // { externalSystemSyncAccountId: "5f071a5840b3b689f8c013c8" },
  ],

  // accountSV_Id: null,
  // accountBB_Id: null,
}

function normalizeDataToSelect(
  data: { [P: string]: string }[],
  valueField: string,
  labelField: string
) {
  const formatData = data.map((item) => {
    item.value = item[valueField]
    item.label = item[labelField]
    return item
  })
  return formatData
}

const CourierExternalSubSystemSelect = ({
  label,
  name,
  options,
  // onChange,
  ...props
}: {
  label: string
  options: any
  onChange?: (...args: any) => any
  name: "externalSystems[0]" | "externalSystems[1]"
  //
}) => {
  const [field, meta, helpers] = useField(props as any)
  return (
    <FTLSelect
      label={`Аккаунт курьера в ${label}`}
      placeholder="Укажите аккаунт"
      options={options}
      {...field}
      {...props}
    />
  )
}

export const NewCourierPage = () => {
  const history = useHistory()
  const page = useLayout({
    components: {
      Breadcrumbs: {
        items: [
          { label: "Курьеры", to: "/couriers" },
          { label: "Новый курьер", to: "#" },
        ],
      },
      Header: {
        title: "Новый курьер",
      },
    },
  })
  // const [state, dispatch] = useReducer(reducer, initState)

  const [countries, setCountries] = useState<any[]>([])
  const [selectedCountry, setSelectedCountry] = useState<null | {
    label: string
    value: string
  }>(null)

  const [cities, setCities] = useState<any[]>([])
  const [selectedCity, setSelectedCity] = useState<
    | undefined
    | {
        label: string
        value: string
      }
  >()

  const [services, setServices] = useState<any[]>([])
  const [selectedService, setSelectedService] = useState<null | {
    label: string
    value: string
  }>(null)

  const [formIsSubmitted, setFormIsSubmitted] = useState(false)
  // const

  const [errorTooltip, setErrorTooltip] = useState<{
    open: boolean
    message: null | string
  }>({ open: false, message: null })

  const [externalSystemSVValue, setExternalSystemSVValue] = useState<null | {}>(
    null
  )
  const [externalSystemBBValue, setExternalSystemBBValue] = useState<null | {}>(
    null
  )

  const [isCreateMoreThanOnce, setIsCreateMoreThanOnce] = useState(false)
  const formik = useFormik({
    initialValues: initState,
    validationSchema: CourierSchema,
    onSubmit: (values) => {
      const formattedPhoneNumber =
        typeof values.phoneNumber === "number"
          ? values.phoneNumber
          : parseInt(values.phoneNumber!.replace(/[\s\-\(\)\+]/g, ""), 10)
      createCourier({
        cityId: values.cityId,
        countryId: values.countryId,
        serviceZoneId: values.serviceId,
        phoneNumber: formattedPhoneNumber,
        firstName: values.firstName,
        lastName: values.lastName,
        middleName: values.middleName,
        externalSystems: values.externalSystems.filter(
          (account) => Object.values(account).length > 0
        ),
      })
        .then(() => {
          formik.setValues(
            {
              ...formik.values,
              firstName: "",
              lastName: "",
              middleName: "",
              phoneNumber: "",
              externalSystems: [],
            },
            false
          )
          setExternalSystemSVValue(null)
          setExternalSystemBBValue(null)
          if (!isCreateMoreThanOnce) history.push("/couriers")
          // localStorage.setItem("defaultCity", JSON.stringify(selectedCity))
          // localStorage.setItem(
          //   "defaultCountry",
          //   JSON.stringify(selectedCountry)
          // )
          // localStorage.setItem(
          //   "defaultService",
          //   JSON.stringify(selectedService)
          // )
        })
        .catch((error) => {
          setErrorTooltip({
            message: error.response.data.errors[0].presentationData.message,
            open: true,
          })
        })
        .finally(() => {
          formik.setSubmitting(false)
        })
    },
  })

  // const handleSelectValue = (value: string) => {
  //   dispatch()
  // }
  // const
  useEffect(() => {
    Promise.all([getCities(), getCountries(), getServiceZones()])
      .then((result) => {
        const [cities, countries, services] = result
        setCities(normalizeDataToSelect(cities.data.result.items, "id", "name"))
        setCountries(
          normalizeDataToSelect(countries.data.result.items, "id", "name")
        )
        setServices(
          normalizeDataToSelect(services.data.result.items, "id", "name")
        )
      })
      .catch((error) => {})
  }, [])

  // useEffect(() => {
  //   const defaultCity = localStorage.getItem("defaultCity")
  //   const defaultCountry = localStorage.getItem("defaultCountry")
  //   const defaultService = localStorage.getItem("defaultService")
  //   if (defaultCity) {
  //     const city = JSON.parse(defaultCity)
  //     formik.setFieldValue("cityId", JSON.parse(defaultCity as string).value)
  //     setSelectedCity({ value: city.value, label: city.label })
  //   }
  //   if (defaultCountry) {
  //     formik.setFieldValue(
  //       "countryId",
  //       JSON.parse(defaultCountry as string).value
  //     )
  //   }
  //   if (defaultService) {
  //     formik.setFieldValue(
  //       "serviceId",
  //       JSON.parse(defaultService as string).value
  //     )
  //   }
  // }, [])

  return (
    <>
      <Box marginBottom="auto">
        {page.Breadcrumbs}
        {page.Header}
        {/* <Formik */}
        {process.env.NODE_ENV === "development" ? (
          <>
            <FTLJsonLogger label="Стейт формы" value={formik.values} />
            <FTLJsonLogger label="Ошибки формы" value={formik.errors} />
          </>
        ) : null}
        <form
          id="new-courier-form"
          onSubmit={(e) => {
            e.preventDefault()
            formik.handleSubmit()
            setFormIsSubmitted(true)
          }}
        >
          <Box display="grid" mt={6} gridGap="24px" width="360px">
            <Box>
              <FTLTextField
                required
                name="lastName"
                label="Фамилия"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.errors.lastName &&
                  formik.submitCount > 0 &&
                  formik.touched.lastName
                    ? true
                    : false
                }
                onBlur={formik.handleBlur}
                // onChange={(e: any) => dispatch(setLastName(e.target.value))}
                fullWidth
              />
            </Box>
            <Box>
              <FTLTextField
                name="firstName"
                required
                label="Имя"
                value={formik.values.firstName}
                onBlur={formik.handleBlur}
                // onChange={(e: any) => dispatch(setFirstName(e.target.value))}
                onChange={formik.handleChange}
                fullWidth
              />
            </Box>
            <Box>
              <FTLTextField
                label="Отчество"
                name="middleName"
                value={formik.values.middleName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                fullWidth
              />
            </Box>

            <Box>
              <NumberFormat
                label="Телефон"
                required
                name="phoneNumber"
                format={(value) => {
                  const firstDigit = value[0]

                  const phone =
                    `${
                      firstDigit === "7"
                        ? "+7"
                        : firstDigit === "8"
                        ? firstDigit
                        : ""
                    }` +
                    " " +
                    `(${value.slice(1, 4)})` +
                    " " +
                    value.slice(4, 7) +
                    "-" +
                    value.slice(7, 9) +
                    "-" +
                    value.slice(9, 11)
                  return phone
                }}
                mask="_"
                error={
                  formik.errors.phoneNumber && formik.touched.phoneNumber
                    ? true
                    : false
                }
                value={formik.values.phoneNumber as number}
                customInput={FTLTextField}
                // onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="Номер телефона"
                fullWidth
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber}
            </Box>
            {/* <PhoneCombinedSelect /> */}
            <Box>
              {/* <FTLSelectAsync
            label="Страна"
            // loadOptions={(inputValu) => }
            placeholder="Укажите страну"
          /> */}
              <FTLSelect
                required
                label="Страна"
                onBlur={formik.handleBlur}
                isError={
                  formik.errors.countryId && formik.submitCount > 0
                    ? true
                    : false
                }
                onChange={(option) => {
                  // FIX IT, see https://github.com/DefinitelyTyped/DefinitelyTyped/issues/32553
                  formik.setFieldValue(
                    "countryId",
                    (option as { value: string })?.value
                  )
                  // setSelectedCountry(option as any)
                }}
                options={countries}
                placeholder="Укажите страну"
                // onChange={handleChange}
                // onChange={(item: any) => {
                //   if (item) {
                //     dispatch(setCountryId(item.value))
                //   }
                // }}
              />
            </Box>
            <Box>
              <FTLSelect
                required
                label="Город"
                options={cities}
                isError={
                  formik.errors.cityId && formik.submitCount > 0 ? true : false
                }
                // defaultValue={selectedCity}
                onChange={(option) => {
                  formik.setFieldValue(
                    "cityId",
                    (option as { value: string }).value
                  )
                  // setSelectedCity(option as any)
                }}
                // onChange={(item: any) => {
                //   if (item) {
                //     dispatch(setCityId(item.value))
                //   }
                // }}
                placeholder="Укажите город"
              />
            </Box>
            <Box>
              <FTLSelect
                required
                label="Зона обслуживания"
                isClearable
                placeholder="Укажите зону обслуживания"
                options={services}
                // defaultValue={selectedService}
                onChange={(option, actionMeta) => {
                  if (actionMeta.action === "select-option") {
                    formik.setFieldValue(
                      "serviceId",
                      (option as { value: string }).value
                    )
                  } else formik.setFieldValue("serviceId", null)
                  // setSelectedService(option as any)
                }}
                isError={
                  formik.errors.serviceId && formik.submitCount > 0
                    ? true
                    : false
                }
              />
            </Box>
            <Box>
              <VirtualizedSelectNew
                serviceId="5f071a5840b3b689f8c013c8"
                label="Аккаунт курьера в СушиВёсла"
                value={externalSystemSVValue as any}
                name="externalSystems[0]"
                isClearable
                onChange={(option: any, actionMeta) => {
                  if (
                    actionMeta.action === "set-value" ||
                    actionMeta.action === "select-option"
                  ) {
                    formik.setFieldValue("externalSystems[0]", {
                      externalSystemSyncAccountId: "5f071a5840b3b689f8c013c8",
                      id: option.value,
                    })
                    setExternalSystemSVValue(option)
                  } else if (actionMeta.action === "clear") {
                    formik.setFieldValue("externalSystems[0]", {}, false)
                    setExternalSystemSVValue(null)
                  }
                }}
              />
            </Box>
            <Box>
              <VirtualizedSelectNew
                serviceId="5f16a79fbdc34adcb186a748"
                label="Аккаунт курьера в БлинБерри"
                isClearable
                value={externalSystemBBValue as any}
                name="externalSystems[1]"
                onChange={(option: any, actionMeta) => {
                  if (
                    actionMeta.action === "set-value" ||
                    actionMeta.action === "select-option"
                  ) {
                    formik.setFieldValue("externalSystems[1]", {
                      externalSystemSyncAccountId: "5f16a79fbdc34adcb186a748",
                      id: option.value,
                    })
                    setExternalSystemBBValue(option)
                  } else if (actionMeta.action === "clear") {
                    formik.setFieldValue("externalSystems[1]", {}, false)
                    setExternalSystemBBValue(null)
                  }
                }}
              />
              {/* <FTLSelect
                label="Аккаунт курьера в БлинБерри"
                placeholder="Укажите аккаунт"
                name="externalSystems[1]"
                options={[
                  { value: "5f16a79fbdc34adcb186a748", label: "BB test" },
                ]}
                // onChange={(item: any) => {
                //   dispatch(setAccountBB(item.id))
                // }}
                onChange={(option: any) => {
                  formik.setFieldValue("externalSystems[1]", {
                    externalSystemSyncAccountId: "5f071a5840b3b689f8c013c8",
                    id: option.value,
                  })
                }}
              /> */}
            </Box>
          </Box>
        </form>
        <FTLSnackbar
          open={errorTooltip.open}
          message={errorTooltip.message as string}
          onClose={() => setErrorTooltip({ open: false, message: null })}
          hideDuration={3000}
          variant="error"
        />
        <FTLSnackbar
          open={formik.isSubmitting && !formik.isValid}
          hideDuration={1500}
          onClose={() => setFormIsSubmitted(false)}
          message="Пожалуйста, заполните обязательные поля"
          variant="error"
        />
        <FTLSnackbar
          open={formik.isValid && formik.isSubmitting}
          hideDuration={2500}
          variant="success"
          message="Данные сохранены"
        />
      </Box>
      <FTLToolbar
        onSaveBtnId="new-courier-form"
        position="sticky"
        async={formik.isSubmitting}
        onSaveMessage="Создать"
      >
        <Box ml={2} display="flex" alignItems="center">
          <FTLCheckbox
            value={isCreateMoreThanOnce}
            onChange={() => setIsCreateMoreThanOnce(!isCreateMoreThanOnce)}
            id="create-once-checkbox"
          />
          <Box>
            <label htmlFor="create-once-checkbox">
              <Typography style={{ ...typography.unsupportedSemanticTags.p2 }}>
                Создать ещё одного
              </Typography>
            </label>
          </Box>
        </Box>
      </FTLToolbar>
    </>
  )
}

// type SetFirstNameAction = {
//   type: "SET_FIRST_NAME"
//   payload: {
//     value: string
//   }
// }

// type SetLastNameAction = {
//   type: "SET_LAST_NAME"
//   payload: {
//     value: string
//   }
// }

// type SetMiddleNameAction = {
//   type: "SET_MIDDLE_NAME"
//   payload: {
//     value: string
//   }
// }

// type SetCountryIdAction = {
//   type: "SET_COUNTRY_ID"
//   payload: {
//     id: string
//   }
// }

// type SetCityIdAction = {
//   type: "SET_CITY_ID"
//   payload: {
//     id: string
//   }
// }

// type SetAccountSVAction = {
//   type: "SET_ACCOUNT_SV_ID"
//   payload: {
//     id: string
//   }
// }

// type SetAccountBBAction = {
//   type: "SET_ACCOUNT_BB_ID"
//   payload: {
//     id: string
//   }
// }

// type SetServiceZoneIdAction = {
//   type: "SET_SERVICE_ZONE_ID"
//   payload: {
//     id: string
//   }
// }

// const setFirstName = (value: string): SetFirstNameAction => {
//   return {
//     type: "SET_FIRST_NAME",
//     payload: {
//       value,
//     },
//   }
// }

// const setLastName = (value: string): SetLastNameAction => {
//   return {
//     type: "SET_LAST_NAME",
//     payload: {
//       value,
//     },
//   }
// }

// const setMiddleName = (value: string): SetMiddleNameAction => {
//   return {
//     type: "SET_MIDDLE_NAME",
//     payload: {
//       value,
//     },
//   }
// }

// const setCountryId = (id: string): SetCountryIdAction => {
//   return {
//     type: "SET_COUNTRY_ID",
//     payload: {
//       id,
//     },
//   }
// }

// const setCityId = (id: string): SetCityIdAction => {
//   return {
//     type: "SET_CITY_ID",
//     payload: {
//       id,
//     },
//   }
// }

// const setAccountSV = (id: string): SetAccountSVAction => {
//   return {
//     type: "SET_ACCOUNT_SV_ID",
//     payload: {
//       id,
//     },
//   }
// }

// const setAccountBB = (id: string): SetAccountBBAction => {
//   return {
//     type: "SET_ACCOUNT_BB_ID",
//     payload: {
//       id,
//     },
//   }
// }

// const setServiceZoneId = (id: string): SetAccountBBAction => {
//   return {
//     type: "SET_ACCOUNT_BB_ID",
//     payload: {
//       id,
//     },
//   }
// }

// type Action =
//   | SetFirstNameAction
//   | SetLastNameAction
//   | SetMiddleNameAction
//   | SetCountryIdAction
//   | SetCityIdAction
//   | SetAccountBBAction
//   | SetAccountSVAction
//   | SetServiceZoneIdAction

// const reducer = (state: typeof initState, action: Action): any => {
//   switch (action.type) {
//     case "SET_FIRST_NAME":
//       return { ...state, firstName: action.payload?.value }
//     case "SET_LAST_NAME":
//       return { ...state, lastName: action.payload.value }
//     case "SET_MIDDLE_NAME":
//       return { ...state, middleName: action.payload.value }
//     case "SET_CITY_ID":
//       return { ...state, cityId: action.payload.id }
//     case "SET_COUNTRY_ID":
//       return { ...state, countryId: action.payload.id }
//     case "SET_ACCOUNT_SV_ID":
//       return { ...state, accountSV_Id: action.payload.id }
//     case "SET_ACCOUNT_BB_ID":
//       return { ...state, accountBB_Id: action.payload.id }
//     case "SET_SERVICE_ZONE_ID":
//       return {
//         ...state,
//         serviceId: action.payload.id,
//       }
//     default:
//       return state
//   }
// }
