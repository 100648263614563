import React from "react"
import { Container, Typography, Box } from "@material-ui/core"
import { typography } from "../shared/theme"
import { FTLButton } from "../components"

export const AccessErrorPage = () => {
  return (
    <Container maxWidth="xs">
      <Box display="flex" mt={4} alignItems="center" flexDirection="column">
        <Box>
          <Typography variant="h1">Ошибка доступа</Typography>
        </Box>
        <Box mt={3}>
          <Typography style={{ ...typography.unsupportedSemanticTags.p3 }}>
            Пожалуйста, выполните вход повторно
          </Typography>
        </Box>
        <Box mt={6}>
          <FTLButton color="primary">Войти</FTLButton>
        </Box>
      </Box>
    </Container>
  )
}
