import React, { useReducer } from "react"
import { Breadcrumbs as MUIBreadcrumbs, makeStyles } from "@material-ui/core"
import { typography } from "../../shared/theme"
import { Link } from "react-router-dom"
import { useBreadcrumbs } from "../../shared/hooks/useBreadcrumbs"

type Props = {
  children?: React.ReactNode
  items: BreadcrumbsState
}

type BreadcrumbType = { to: string; label: string }

type BreadcrumbsState = BreadcrumbType[]

type BreacrumbsAction =
  | {
      type: "ADD_BREADCRUMB"
      breadcrumb: BreadcrumbType
    }
  | {
      type: "REMOVE_BREADCRUMB"
      breadcrumb: BreadcrumbType
    }

// const breadcrumbsInitialState: BreadcrumbsState = [
//   { to: "/sms-service/", label: "SMS-сервисы" },
//   { to: "/sms-service/", label: "Параметры SMS-сервисов" },
//   { to: "/sms-services/new", label: "Новый сервис" },
// ]

const breadcrumbsReducer = (
  state: any,
  action: BreacrumbsAction
): BreadcrumbsState => {
  switch (action.type) {
    case "ADD_BREADCRUMB": {
      return [...state, action.breadcrumb]
    }
    case "REMOVE_BREADCRUMB": {
      // const itemIsExist = state.indexOf(action.breadcrumb)
      return [...state]
    }
    default:
      throw Error("unexpected breadcrumbs action")
  }
}

export const FTLBreadcrumbs = ({ items }: Props) => {
  const classes = useStyles()
  // const [state, addNewBreadcrumb] = useReducer(breadcrumbsReducer, items)
  // const [items] = useBreadcrumbs()
  return (
    <MUIBreadcrumbs classes={{ li: classes.item, separator: classes.item }}>
      {items.map((item, index) => (
        <Link to={item.to} key={index}>
          {item.label}
        </Link>
      ))}
    </MUIBreadcrumbs>
  )
}

const useStyles = makeStyles((theme) => ({
  item: {
    color: "#7c8a9c",
    ...typography.unsupportedSemanticTags.p2,
  },
}))
