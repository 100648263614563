export function getCookie(name: string) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop()?.split(";").shift()
}

export function deleteCookie(name: string, path?: string, domain?: string) {
  if (getCookie(name)) {
    document.cookie =
      name +
      "=" +
      (path ? ";path=" + path : "") +
      (domain ? ";domain=" + domain : "") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT"
  }
}

export function once(fn: any, context?: any) {
  let result: any

  return function () {
    if (fn) {
      // @ts-ignore
      const self = this
      result = fn.apply(context || self, arguments)
      fn = null
    }

    return result
  }
}

// export function throttle(fn: Function, wait: number) {
//   let isCalled = false

//   return function (...args: any[]) {
//     if (!isCalled) {
//       fn(...args)
//       isCalled = true
//       setTimeout(function () {
//         isCalled = false
//       }, wait)
//     }
//   }
// }

// export function throttle(func: Function, ms: number) {
//   let isThrottled = false
//   let savedArgs: any
//   let savedThis: any
//   function wrapper(this: any) {
//     if (isThrottled) {
//       savedArgs = arguments
//       savedThis = this
//       return
//     }

//     func.apply(this, arguments)

//     isThrottled = true

//     setTimeout(function () {
//       isThrottled = false
//       if (savedArgs) {
//         wrapper.apply(savedThis, savedArgs)
//         savedArgs = savedThis = null
//       }
//     }, ms)
//   }

//   return wrapper
// }

export function throttle(fn: Function, wait: number) {
  let isCalled = false

  return function (...args: any[]) {
    if (!isCalled) {
      fn(...args)
      isCalled = true
      setTimeout(function () {
        isCalled = false
      }, wait)
    }
  }
}

export const wait = (amount = 0) =>
  new Promise((resolve) => setTimeout(resolve, amount))

export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  waitFor: number
) => {
  let timeout: ReturnType<typeof setTimeout> | null = null

  const debounced = (...args: Parameters<F>) => {
    if (timeout !== null) {
      clearTimeout(timeout)
      timeout = null
    }
    timeout = setTimeout(() => func(...args), waitFor)
  }

  return debounced as (...args: Parameters<F>) => ReturnType<F>
}
