import React, { useMemo } from "react"
import { useLayout } from "../../shared/hooks/useLayout"
import { useHistory } from "react-router-dom"
import { Box, IconButton } from "@material-ui/core"
import { Edit, Remove, Delete } from "@material-ui/icons"

export const TimeZonesPage = () => {
  const history = useHistory()
  const columns = useMemo(() => {
    return [
      {
        Header: "Наименование",
        accessor: "title",
        disableSortBy: true,
      },
      {
        Header: "Временная зона",
        accessor: "timezone",
        disableSortBy: true,
      },
      {
        Header: "",
        accessor: "action",
        disableSortBy: true,
      },
    ]
  }, [])
  const data = useMemo(() => {
    return [
      {
        title: "UTC+3:00 (Москва)",
        timezone: "Европа/Москва",
        action: (
          <Box display="flex" justifyContent="flex-end">
            <IconButton size="small">
              <Edit color="secondary" />
            </IconButton>
            <IconButton size="small">
              <Delete color="secondary" />
            </IconButton>
          </Box>
        ),
      },
      {
        title: "UTC+4:00 (Самара)",
        timezone: "Европа/Самара",
        action: (
          <Box display="flex" justifyContent="flex-end">
            <IconButton size="small">
              <Edit color="secondary" />
            </IconButton>
            <IconButton size="small">
              <Delete color="secondary" />
            </IconButton>
          </Box>
        ),
      },
    ]
  }, [])

  const page = useLayout({
    components: {
      Header: {
        title: "Часовые пояса",
        button: {
          label: "Новый часовый пояс",
          onClick: () => history.push("/time-zones/new"),
        },
      },
      Table: {
        columns,
        data,
      },
    },
  })

  return (
    <>
      <Box>{page.Header}</Box>
      <Box mt={6}>{page.Table}</Box>
    </>
  )
}
