import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { Provider } from "react-redux"
import {
  configureStore,
  getDefaultMiddleware,
  createSlice,
  combineReducers,
} from "@reduxjs/toolkit"
import "./globals.css"
import Thunk from "redux-thunk"
import { createBrowserHistory } from "history"

const history = createBrowserHistory()

// const middleware = [...getDefaultMiddleware()]

const slice = createSlice({
  name: "rootReducer",
  initialState: "",
  reducers: {},
})

const rootReducer = combineReducers({ stub: slice.reducer })

const store = configureStore({ reducer: rootReducer, devTools: true })

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
