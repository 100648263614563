import React, { useState, useMemo, useEffect } from "react"
import { Container, Box, Grid, Typography, makeStyles } from "@material-ui/core"
import Logo from "../assets/img/icons/FTLLogo.svg"
import D from "../assets/img/bg-auth.svg"
import { FTLTextField, FTLButton, FTLPasswordField } from "../components"
import { useAuthContext } from "auth-module-front"
import { typography, palette } from "../shared/theme"
import { useHistory, Link } from "react-router-dom"
import { FTLSnackbar } from "../components/ui/FTLSnackbar"

export const AuthPage = () => {
  const { state, dispatch } = useAuthContext()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const history = useHistory()
  const errorResponse = useMemo(
    () => state.errors?.find((error: any) => error.presentationData.message),
    [state.errors]
  )

  const [snackbarIsOpen, setSnackbarIsOpen] = useState(false)

  useEffect(() => {
    if (Boolean(errorResponse)) {
      setSnackbarIsOpen(true)
    }
  }, [errorResponse])

  const handleOnAuth = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // const isKeyboardEvent = (
    //   e:
    //     | React.KeyboardEvent<HTMLButtonElement>
    //     | React.MouseEvent<HTMLButtonElement>
    // ): e is React.KeyboardEvent<HTMLButtonElement> => {
    //   return (e as React.KeyboardEvent<HTMLButtonElement>).keyCode !== undefined
    // }
    // if ((isKeyboardEvent(e) && e.keyCode === 13) || !isKeyboardEvent(e)) {
    dispatch({
      type: "SIGN_IN",
      payload: {
        loginField: {
          propName: "email",
          value: email,
        },
        passwordField: {
          propName: "password",
          value: password,
        },
      },
    })
  }

  const classes = useStyles()

  const emailHasError =
    errorResponse?.details === "INVALID_EMAIL" ||
    errorResponse?.details === "INVALID_DATA" ||
    errorResponse?.details === "ACCOUNT_IS_BLOCKED"
  const passwordHasError =
    errorResponse?.details === "INVALID_DATA" ||
    errorResponse?.details === "INVALID_PASSWORD"

  useEffect(() => {
    dispatch({ type: "CHECKOUT_AUTH" })
  }, [])

  useEffect(() => {
    if (state.isAuth) {
      history.push("/couriers")
    }
  }, [state.isAuth])
  return (
    <Box
      style={{
        backgroundImage: `url(${D})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
        backgroundSize: "100% auto",
      }}
      // mb="40px"
    >
      <Container
        maxWidth="md"
        disableGutters
        classes={{
          maxWidthXs: classes.maxWidthXs,
        }}
      >
        <Box display="flex" justifyContent="center">
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            height="calc(100vh - 40px)"
            mb="40px"
          >
            <Box margin="auto auto 0">
              <Box textAlign="center">
                <img src={Logo} alt="logo" />
              </Box>
              <Box mt={6}>
                <Typography variant="h3">Войти в Tech Admin</Typography>
              </Box>
            </Box>
            <Box mt={6}>
              <form noValidate onSubmit={handleOnAuth}>
                <Box width="280px">
                  <FTLTextField
                    name="email"
                    fullWidth
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={emailHasError}
                  />
                </Box>
                <Box width="280px" mt={6}>
                  <FTLPasswordField
                    fullWidth
                    name="password"
                    label="Пароль"
                    onChange={(e) => setPassword(e.target.value)}
                    error={passwordHasError}
                  />
                </Box>
                <Box mt={6}>
                  <FTLButton
                    fullWidth
                    async={state.isLoading}
                    color="primary"
                    type="submit"
                    size="large"
                    // onClick={handleOnAuth}
                    // onKeyDown={handleOnAuth}
                    // onEnte
                  >
                    Войти
                  </FTLButton>
                </Box>
              </form>
            </Box>
            <Box mt={12} marginLeft="auto" marginRight="auto" display="flex">
              <Box>
                <Link to="/">
                  <Typography
                    style={{
                      ...typography.unsupportedSemanticTags.lawExplanations,
                      color: palette.link.secondary,
                    }}
                  >
                    Конфиденциальность
                  </Typography>
                </Link>
              </Box>
              <Box ml={4}>
                <Link to="/">
                  <Typography
                    style={{
                      ...typography.unsupportedSemanticTags.lawExplanations,
                      color: palette.link.secondary,
                    }}
                  >
                    Безопасность
                  </Typography>
                </Link>
              </Box>
            </Box>
            <Box mt="auto">
              <Link to="/">
                <Typography
                  align="center"
                  style={{
                    ...typography.unsupportedSemanticTags.lawExplanations,
                    color: palette.link.secondary,
                  }}
                >
                  ©2020 FoodTech Lab
                </Typography>
              </Link>
            </Box>
          </Box>
        </Box>
        <FTLSnackbar
          variant="error"
          open={snackbarIsOpen}
          onClose={() => setSnackbarIsOpen(false)}
          message={errorResponse?.presentationData.message}
        />
      </Container>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  maxWidthXs: {
    maxWidth: "280px",
  },
}))
