import React, { useState, useMemo } from "react"
import { Box, Typography, IconButton } from "@material-ui/core"
import { FTLButton } from "../../components"
import {
  FTLTableCell,
  FTLTableHeadCell,
} from "../../components/ui/Table/TableCell"
import {
  FTLTableRowHead,
  FTLTableRow,
} from "../../components/ui/Table/TableRow"
import { useHistory } from "react-router-dom"
import { BreadcrumbType } from "../../pages/SmsServices/Params"
import { Edit, SvgIconComponent } from "@material-ui/icons"
import { FTLTable } from "../../components/ui/Table/FTLTable"

type Props = {
  handleNewServiceBtn: (breadcrumb: BreadcrumbType) => void
}

// const items = (cells: string[]) => {
//   return cells
// }
type CellType = {
  value: any
  type?: "normal" | "action" | "data"
}

type RowType = {
  items: CellType[]
  actions?: {
    icon: any
  }
}

export const SmsServicesParameters = ({ handleNewServiceBtn }: Props) => {
  const history = useHistory()
  const columns = useMemo(() => {
    return [
      {
        Header: "Наименование",
        accessor: "title",
        disableSortBy: true,
      },
      {
        Header: "",
        accessor: "action",
        disableSortBy: true,
      },
    ]
  }, [])
  const data = useMemo(() => {
    return [
      {
        title: "SMS сервис 1",
        action: (
          <IconButton color="secondary">
            <Edit />
          </IconButton>
        ),
      },
      {
        title: "SMS сервис 2",
        action: (
          <IconButton color="secondary">
            <Edit />
          </IconButton>
        ),
      },
      {
        title: "SMS сервис 3",
        action: (
          <IconButton color="secondary">
            <Edit />
          </IconButton>
        ),
      },
    ]
  }, [])

  // const actions = useMemo(() => {
  //   return [
  //     {
  //       IconComponent: Edit,
  //     },
  //   ]
  // }, [])

  return (
    <>
      <Box mt={1} display="flex" justifyContent="space-between">
        <Typography variant="h1">Параметры SMS-сервисов</Typography>
        <FTLButton
          color="primary"
          onClick={() => {
            handleNewServiceBtn({
              to: "/sms-services/new",
              label: "Новый сервис",
            })
            history.push("/sms-services/new") //!  FIX ME
          }}
        >
          Новый сервис
        </FTLButton>
      </Box>
      <Box mt={6}>
        <FTLTable columns={columns} pageCount={1} data={data} />
      </Box>
    </>
  )
}

{
  /* columns={[{Cell: p => {
          // console.log(p.cell.getCellProps().)
        }}]}  */
}
{
  /* <FTLTable
          columns={[
            { title: "Наименование", field: "name" },
            { title: "test", field: "test1" },
          ]}
          data={[
            { name: "SMS service 1", test1: "asd" },
            { name: "SMS service 2", test1: "dsf" },
          ]}
          actions={[{ icon: Edit }]}
        /> */
}
{
  /* <thead>
            <FTLTableRowHead>
              <FTLTableHeadCell>Наименование</FTLTableHeadCell>
              <FTLTableHeadCell></FTLTableHeadCell>
            </FTLTableRowHead>
          </thead>
          {rows.map((row, index) => (
            <FTLTableRow>
              {row.items.map((item) => (
                <FTLTableCell>{item.value}</FTLTableCell>
              ))}
            </FTLTableRow>
          ))} */
}
{
  /* {rows.map((row, index) => (
            <FTLTableRow tabIndex={index}>
              <FTLTableCell></FTLTableCell>
            </FTLTableRow>
          ))} */
}

{
  /* <FTLTableRow>
            <FTLTableCell>SMS сервис 1</FTLTableCell>
            <FTLTableCell textAlign="right">
              <IconButton>
                <Edit color="secondary" />
              </IconButton>
            </FTLTableCell>
          </FTLTableRow> */
}
