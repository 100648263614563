import React, { useEffect, useMemo } from "react"
import { Box } from "@material-ui/core"
import { FTLHeader } from "../../components/ui/FTLHeader"
import { FTLBreadcrumbs } from "../../components/ui/FTLBreadcrumbs"
import { FTLTable } from "../../components/ui/Table/FTLTable"
import { Column } from "react-table"
import { SvgIconComponent } from "@material-ui/icons"
import { FTLToolbar } from "../../components/ui/FTLToolbar"

// TODO переписать на плагичные хуки
// TODO типизировать

type BreadcrumbType = { to: string; label: string }
type BreadcrumbsState = BreadcrumbType[]

// type Props = {
// header?: {
//   title?: string
//   button?: {
//     label: string
//     onClick: () => void
//   }
// }
//   breadcrumbs?: BreadcrumbsState[]
// }

type Props = {
  components?: {
    Header?: {
      title?: string
      button?: {
        label: string
        onClick?: () => void
      }
    }
    Breadcrumbs?: {
      items?: BreadcrumbsState
    }
    Table?: {
      columns: Column[]
      data: any[]
      actions?: {
        IconComponent: SvgIconComponent
        onClick?: (e: React.MouseEvent) => void
      }[]
      onFetchData?: (...args: any) => void
    }
    Toolbar?: { onSaveMessage?: string }
  }
}

type BreacrumbsAction =
  | {
      type: "ADD_BREADCRUMB"
      breadcrumb: BreadcrumbType
    }
  | {
      type: "REMOVE_BREADCRUMB"
      breadcrumb: BreadcrumbType
    }

enum Components {
  Header = "Header",
  Breadcrumbs = "Breadcrumbs",
  Table = "Table",
  Toolbar = "Toolbar",
}

const getComponent = (name: string, props: any) => {
  switch (name) {
    case "Header":
      return <FTLHeader {...props} />
    case "Breadcrumbs":
      return <FTLBreadcrumbs {...props} />
    case "Table":
      return <FTLTable {...props} />
    case "Toolbar":
      return <FTLToolbar {...props} />
    default:
      throw Error(`Component by name ${name} doesn't exist`)
  }
}

export const useLayout = ({ components }: Props) => {
  const initComponents = useMemo(() => {
    return Object.entries(components ?? {}).reduce(
      (obj: { [P in Components]?: JSX.Element }, component) => {
        const [ComponentName, props] = component as [Components, any]
        obj[ComponentName] = getComponent(ComponentName, props)
        return obj
      },
      {}
    )
  }, [components])
  return { ...initComponents }
}
