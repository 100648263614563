import React, { useState, useEffect } from "react"
import { typography, palette } from "../../shared/theme"
import {
  makeStyles,
  InputAdornment,
  IconButton,
  TextFieldProps,
  SvgIcon,
} from "@material-ui/core"
import { FTLTextField } from "./TextField"
import {
  Search,
  Visibility,
  KeyboardArrowDownOutlined,
} from "@material-ui/icons"

type Props = { onChange?: (value: any) => void } & Omit<
  TextFieldProps,
  "onChange"
>

export const FTLSearchField = ({
  onChange = (value: any) => {},
  ...otherProps
}: Props) => {
  const classes = useStyles()
  const [value, setValue] = useState("")

  useEffect(() => {
    onChange(value)
  }, [value])

  const resetValue = () => setValue("")
  return (
    <FTLTextField
      type="text"
      size="small"
      value={value}
      className={classes.root}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value)
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              size="small"
              tabIndex={-1}
              // disableFocusRipple
              //   className={classes.icon}
              //   onClick={handleClickShowPassword}
            >
              {value.length === 0 ? (
                <Search
                  color="secondary"
                  fontSize="small"
                  classes={{ fontSizeSmall: classes.icon }}
                />
              ) : (
                <SvgIcon
                  fontSize="small"
                  viewBox="0 0 20px 20px"
                  // className={`MuiSelect-icon ${classes.cancelIcon}`}
                  // fontSize="small"
                  onClick={(e) => {
                    resetValue()
                  }}
                >
                  <path
                    fill-rule="evenodd"
                    clipRule="evenodd"
                    d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17ZM12.8292 7.17543C13.1221 7.46832 13.1221 7.9432 12.8292 8.23609L11.0653 9.99992L12.8331 11.7677C13.126 12.0606 13.126 12.5355 12.8331 12.8283C12.5402 13.1212 12.0653 13.1212 11.7724 12.8283L10.0047 11.0606L8.23297 12.8323C7.94007 13.1252 7.4652 13.1252 7.17231 12.8323C6.87941 12.5394 6.87941 12.0645 7.17231 11.7716L8.94401 9.99992L7.17965 8.23556C6.88676 7.94267 6.88676 7.46779 7.17965 7.1749C7.47255 6.88201 7.94742 6.88201 8.24031 7.1749L10.0047 8.93926L11.7685 7.17543C12.0614 6.88254 12.5363 6.88254 12.8292 7.17543Z"
                    fill="#A9B1BC"
                  />
                </SvgIcon>
              )}
              {/* {isVisible ? (
            <Visibility htmlColor={palette.icon.secondary} />
          ) : (
            <VisibilityOutlined htmlColor={palette.icon.secondary} />
          )} */}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...otherProps}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "160px",
    maxWidth: "200px",
    "& input::placeholder": {
      color: "#6C7889",
      opacity: 1,
    },
  },
  formControl: {
    // minWidth: "240px",
    minWidth: "160px",
    maxWidth: "240px",
    // margin: theme.spacing(1),
    "& > div": {
      marginTop: theme.spacing(2),
      backgroundColor: "#F9FAFA",
      // '',
      "&.Mui-focused fieldset": {
        border: "2px solid #66a6ff !important",
      },
      "&:hover fieldset": {
        backgroundColor: "#EBEDF0",
        border: "1px solid",
        borderColor: "#C4CBD4 !important",
      },
    },
  },
  label: {
    color: theme.palette.text.tertiary,
    position: "static",
    transform: "none",
    ...typography.supportedTags.h5,
  },
  rootSelect: {
    padding: "10px 12px",
    ...typography.unsupportedSemanticTags.listItemText,
    zIndex: 1,
    // '& > input':
  },
  icon: {},
  select: {
    // "&:focus": {
    //   border: "2px solid #66A6FF",
    // },
  },
  focus: {
    color: "#627084 !important",
  },
  menu: {},
  menuList: {
    "& > li": {
      padding: "6px 12px",
      ...typography.unsupportedSemanticTags.listItemText,
    },
  },
  placeholder: {
    color: theme.palette.text.placeholder,
  },
  cancelIcon: {
    zIndex: 1,
    right: "32px",
    pointerEvents: "initial",
    cursor: "pointer",
    top: "calc(50% - 8px)",
  },
  disabled: {
    backgroundColor: "#F3F4F6",
  },
}))
