import React, { useState, useEffect } from "react"
import {
  Snackbar,
  SvgIconProps,
  Box,
  Paper,
  Typography,
  makeStyles,
  Icon,
  SvgIcon,
} from "@material-ui/core"
import { typography } from "../../shared/theme"
import {
  Close,
  Error,
  CheckCircleOutline,
  CheckCircleSharp,
} from "@material-ui/icons"
import CloseIcon from "../assets/img/icons/ic_close_24.svg"

type Props = {
  open?: boolean
  hideDuration?: number
  icon?: React.ReactElement<SvgIconProps>
  onClose?: () => void
  message?: string
  variant?: "error" | "success" | "primary"
}
export const FTLSnackbar = ({
  open,
  message,
  hideDuration = 3000,
  onClose,
  icon,
  variant,
}: Props) => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(open)

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={hideDuration}
      className={classes.root}
      onClose={() => {
        setIsOpen(false)
        if (onClose) {
          onClose()
        }
      }}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
    >
      <Box className={classes.body} component={Paper}>
        <Icon className={classes.passIcon}>
          {variant === "error" ? (
            <Error />
          ) : variant === "success" ? (
            <CheckCircleSharp htmlColor="#2FBA5E" />
          ) : (
            icon
          )}
        </Icon>
        <Box maxWidth="292px">
          <Typography
            style={{
              ...typography.unsupportedSemanticTags.p3,
              padding: "0 14px",
            }}
          >
            {message}
          </Typography>
        </Box>
        {/* <Close onClick={onClose} className={classes.closeIcon} /> */}
        <SvgIcon onClick={onClose} className={classes.closeIcon}>
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M16.9498 7.05025C16.5592 6.65972 15.9261 6.65972 15.5356 7.05025L12 10.5858L8.46449 7.05025C8.07397 6.65972 7.4408 6.65972 7.05028 7.05025C6.65975 7.44077 6.65975 8.07394 7.05028 8.46446L10.5858 12L7.05028 15.5355C6.65975 15.9261 6.65975 16.5592 7.05028 16.9497C7.4408 17.3403 8.07397 17.3403 8.46449 16.9497L12 13.4142L15.5356 16.9497C15.9261 17.3403 16.5592 17.3403 16.9498 16.9497C17.3403 16.5592 17.3403 15.9261 16.9498 15.5355L13.4142 12L16.9498 8.46446C17.3403 8.07394 17.3403 7.44077 16.9498 7.05025Z"
              fill="#52637A"
            />
          </svg>
        </SvgIcon>
      </Box>
    </Snackbar>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    bottom: "40px",
  },
  body: {
    padding: "10px",
    // bottom: "40px",
    display: "flex",
    // alignItems: "center",
    minWidth: "245px",
    boxShadow:
      "0px 16px 16px rgba(0, 0, 0, 0.12), 0px 0px 8px rgba(0, 0, 0, 0.08)",
  },
  passIcon: {
    marginRight: "auto",
    color: theme.palette.text.error,
  },
  closeIcon: {
    marginLeft: "auto",
    cursor: "pointer",
  },
}))
