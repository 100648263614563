import React, { useState, useReducer, useEffect } from "react"
import { Breadcrumbs, Typography, Box } from "@material-ui/core"
import {
  Link,
  MemoryRouter,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom"
import { FTLTable } from "../../components/ui/Table/TableRaw"
import {
  FTLTableRowHead,
  FTLTableRow,
} from "../../components/ui/Table/TableRow"
import { FTLTableCell } from "../../components/ui/Table/TableCell"
import { FTLButton } from "../../components"
import { FTLTablePaginationButton } from "../../components/ui/Table/FTLTablePaginationButton"
import { FTLBreadcrumbs } from "../../components/ui/FTLBreadcrumbs"
import { SmsServicesParameters } from "../../layouts/SmsServices/Params"
import { NewSmsService } from "../../layouts/SmsServices/NewService"

export type BreadcrumbType = { to: string; label: string }

type BreadcrumbsState = BreadcrumbType[]

const breadcrumbsInitialState: BreadcrumbsState = [
  { to: "/sms-services/params", label: "SMS-сервисы" },
  { to: "/sms-services/params", label: "Параметры SMS-сервисов" },
  // { to: "/sms-services", label: "Новый сервис" },
]

type BreacrumbsAction =
  | {
      type: "ADD_BREADCRUMB"
      breadcrumb: BreadcrumbType
    }
  | {
      type: "REMOVE_BREADCRUMB"
      breadcrumb: BreadcrumbType
    }

const breadcrumbsReducer = (
  state: typeof breadcrumbsInitialState,
  action: BreacrumbsAction
): BreadcrumbsState => {
  switch (action.type) {
    case "ADD_BREADCRUMB": {
      return [...state, action.breadcrumb]
    }
    case "REMOVE_BREADCRUMB": {
      // const itemIsExist = state.indexOf(action.breadcrumb)
      return [...state]
    }
    default:
      throw Error("unexpected breadcrumbs action")
  }
}

type Props = {
  // breadcrumbs: { to: string; label: string }[]
  // dispatchBreadcrumbsAction: React.Dispatch<BreacrumbsAction>
  title?: string
}

export const SmsServicesParamsPage = ({
  // breadcrumbs,
  // dispatchBreadcrumbsAction,
  title,
}: Props) => {
  const [breadcrumbsItems, dispatchBreadcrumbsAction] = useReducer(
    breadcrumbsReducer,
    breadcrumbsInitialState
  ) // REFACTOR ME
  const history = useHistory()
  const location = useLocation()

  const addBreadcrumb = (breadcrumb: BreadcrumbType): void => {
    dispatchBreadcrumbsAction({ type: "ADD_BREADCRUMB", breadcrumb })
    // console.log(history)
  }

  const removeBreadcrumb = (breadcrumb: BreadcrumbType): void => {
    dispatchBreadcrumbsAction({ type: "REMOVE_BREADCRUMB", breadcrumb })
    // console.log(history)
  }

  // useEffect(() => {
  //   console.log(breadcrumbsItems)
  // }, [breadcrumbsItems])

  return (
    <>
      {/* <MemoryRouter
        initialEntries={["/sms-service/", "/new-service"]}
        initialIndex={0}
      > */}
      {/* <Box display="flex" justifyContent="space-between">
          <Typography variant="h1">Параметры SMS-сервисов</Typography>
          <FTLButton
            color="primary"
            onClick={() => {
              handleNewServiceBtn({ to: "/new-service", label: "Новый сервис" })
              history.push("/new-service") //!  FIX ME
            }}
          >
            Новый сервис
          </FTLButton>
        </Box> */}
      <FTLBreadcrumbs items={breadcrumbsInitialState} />

      {/* <Route exact path="/sms-services/params"> */}
      <SmsServicesParameters
        handleNewServiceBtn={
          (breadcrumb: BreadcrumbType) => {}
          // addBreadcrumb(breadcrumb)
        }
      />
      {/* </Route> */}
    </>
  )
}
