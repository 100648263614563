import React, { useState } from "react"
import { Box } from "@material-ui/core"
import { FTLPasswordField } from "../../components"

export const SecurityTab = () => {
  const [password, setPassword] = useState()
  const [repeatPassword, setRepeatPassword] = useState()
  return (
    <Box display="flex">
      <Box mt={6} width="360px" display="grid" gridGap="24px">
        {/* <Box> */}
        <FTLPasswordField fullWidth label="Пароль" required value={password} />
        {/* </Box> */}
        {/* <Box> */}
        <FTLPasswordField
          fullWidth
          required
          label="Повторите пароль"
          value={repeatPassword}
        />
        {/* </Box> */}
      </Box>
    </Box>
  )
}
