import React, { useState } from "react"
import { Box, makeStyles, Typography } from "@material-ui/core"
import { FTLTextField } from "../../components"
import Select from "react-select"
import { palette } from "../../shared/theme"

export const ContactDataTab = () => {
  const [phoneMasks, setPhoneMasks] = useState([{ label: "+7", value: 7 }])
  const classes = useStyles()
  return (
    <Box mt={6} display="flex">
      <Box width="360px" display="grid" gridGap="24px">
        {/* <FTLTextField
          label="Телефон"
          name="phone"
          fullWidth
          value="(999) 999-99-99"
        /> */}

        <FTLTextField name="email" required label="Почта" />

        <Box mt={1}>
          <Box>
            <Typography variant="h5" style={{ color: palette.text.tertiary }}>
              Телефон
            </Typography>
          </Box>
          <Box mt={2} display="flex">
            <Box width="96px" className={classes.phoneMask}>
              <Select
                options={[{ label: "+7", value: 7 }]}
                defaultValue={phoneMasks[0]}
              />
            </Box>
            <Box ml={2} width="100%" className={classes.phone}>
              <FTLTextField
                // label="Телефон"
                name="phone"
                fullWidth
                value="(999) 999-99-99"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  phoneMask: {
    "& > div > div": {
      minHeight: "40px",
    },
  },
  phone: {
    "& > div > div > fieldset": {
      top: 0,
    },
  },
})
