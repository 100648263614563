import React from "react"
import { Header } from "./Header"
import { SideMenu } from "./SideMenu"

import {
  Sms as SmsIcon,
  Notifications as NotificationsIcon,
  Settings as SettingsIcon,
  People,
  Schedule,
} from "@material-ui/icons"
import { Box, Container, SvgIcon } from "@material-ui/core"

type Props = {
  children: JSX.Element
  style?: {}
}

export const BaseLayout = ({ style, children }: Props) => {
  return (
    <>
      <Header />
      <Box
        width="100%"
        // position="fixed"
        display="flex"
        // display="grid"
        // gridTemplateColumns="1fr 1fr"
        // gridGap="100px"
      >
        <SideMenu
          items={[
            {
              icon: <SmsIcon />,
              label: "SMS-сервис",
              linkTo: "/sms-services/",
              items: [
                {
                  label: "Параметры сервисов",
                  linkTo: "/sms-services/params",
                },
                {
                  label: "Коды авторизации",
                  linkTo: "/sms-services/codes",
                },
              ],
            },
            {
              icon: <NotificationsIcon />,
              label: "Push-сервис",
              linkTo: "/push-services",

              // items: [
              //   {
              //     label: "test1",
              //     linkTo: "/profile",
              //   },
              // ],
            },
            {
              icon: <People />,
              label: "Пользователи",
              linkTo: "/users",
            },
            {
              icon: (
                <SvgIcon viewBox="0 0 20 20" fontSize="small">
                  <path
                    fill-rule="evenodd"
                    clipRule="evenodd"
                    d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10.75 6C10.75 5.58579 10.4142 5.25 10 5.25C9.58579 5.25 9.25 5.58579 9.25 6V11C9.25 11.2841 9.4105 11.5438 9.66459 11.6708L13.6646 13.6708C14.0351 13.8561 14.4856 13.7059 14.6708 13.3354C14.8561 12.9649 14.7059 12.5144 14.3354 12.3292L10.75 10.5365V6Z"
                    fill="#132339"
                  />
                </SvgIcon>
              ),
              label: "Часовые пояса",
              linkTo: "/time-zones",
            },
            {
              icon: (
                <SvgIcon viewBox="0 0 20 20" fontSize="small">
                  <path
                    fill-rule="evenodd"
                    clipRule="evenodd"
                    d="M17.7753 14.3821L14.3468 13.0107C15.4048 12.2682 16.1016 11.0439 16.1016 9.65625C16.1016 9.45855 16.0798 9.26379 16.052 9.07031H16.6875C17.0114 9.07031 17.2734 8.80824 17.2734 8.48438C17.2734 7.52988 16.8077 6.68867 16.099 6.15344C16.099 6.15123 16.0996 6.14921 16.1003 6.14718C16.1009 6.14507 16.1016 6.14295 16.1016 6.14062V4.92969C16.1016 3.31434 14.7872 2 13.1719 2H10.8281C9.21277 2 7.89844 3.31434 7.89844 4.92969V6.14062C7.89844 6.14295 7.89908 6.14505 7.89973 6.14715C7.90035 6.14918 7.90098 6.15121 7.90102 6.15344C7.19227 6.68867 6.72656 7.52988 6.72656 8.48438C6.72656 8.80824 6.98863 9.07031 7.3125 9.07031H7.94801C7.9202 9.26379 7.89844 9.45855 7.89844 9.65625C7.89844 11.0439 8.59523 12.2682 9.65324 13.0107L6.22473 14.3821C5.10551 14.8296 4.38281 15.8973 4.38281 17.1023V21.4141C4.38281 21.7379 4.64488 22 4.96875 22H6.72656V19.0312C6.72656 18.944 6.75578 18.8334 6.78836 18.7692L7.96023 16.4254C8.0598 16.2269 8.26234 16.1016 8.48438 16.1016H15.5156C15.7377 16.1016 15.9402 16.2269 16.0398 16.4254L17.2116 18.7692C17.2442 18.8334 17.2734 18.944 17.2734 19.0312V22H19.0312C19.3551 22 19.6172 21.7379 19.6172 21.4141V17.1023C19.6172 15.8973 18.8945 14.8295 17.7753 14.3821ZM12 14.6871L11.0707 13.7578H12.9293L12 14.6871ZM12 12.5859C10.3846 12.5859 9.07031 11.2716 9.07031 9.65625C9.07031 9.45742 9.09629 9.26242 9.13531 9.07031H14.8646C14.9037 9.26242 14.9296 9.45742 14.9296 9.65625C14.9296 11.2716 13.6153 12.5859 12 12.5859Z"
                    fill="#132339"
                  />
                  <path
                    d="M7.89844 19.6172V22H16.1016V19.6172H7.89844Z"
                    fill="#132339"
                  />
                  <path
                    d="M8.84656 17.2734H15.1534L15.7394 18.4453H8.26062L8.84656 17.2734Z"
                    fill="#132339"
                  />
                </SvgIcon>
              ),
              label: "Курьеры",
              linkTo: "/couriers",
            },
            // {
            //   icon: <NotificationsIcon />,
            //   label: "Пользователи",
            //   items: [
            //     // {
            //     //   label: "test1",
            //     //   linkTo: "/profile",
            //     // },
            //   ],
            // },
            {
              icon: <SettingsIcon />,
              label: "Параметры системы",
              linkTo: "/settings",
              // items: [
              //   // {
              //   //   label: "test1",
              //   //   linkTo: "/profile",
              //   // },
              // ],
            },
            {
              icon: <SettingsIcon />,
              label: "Всячина",
              items: [
                {
                  label: "Страница ошибка доступа",
                  linkTo: "/access-error",
                },
                {
                  label: "Страница Доступ запрещён",
                  linkTo: "/permission-denied",
                },
                {
                  label: "temp",
                  linkTo: "/temp",
                },
              ],
            },
          ]}
        />
        <Box mt={6} width="100%" display="flex">
          <Container maxWidth="md" style={style} disableGutters>
            {children}
          </Container>
        </Box>
      </Box>
    </>
  )
}
