import React, { useState } from "react"
import { Modal, makeStyles } from "@material-ui/core"

type Props = {
  open: boolean
  children?: React.ReactNode
}
export const FTLModal = ({ open, children }: Props) => {
  const classes = useStyles()

  // const body = <div className={classes.paper}>{children}</div>

  return (
    <Modal
      style={{
        zIndex: 1301,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className={classes.modal}
      open={open}
    >
      <div className={classes.paper}>{children}</div>
    </Modal>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 320,
    // top: "50%",
    // left: "50%",
    outline: "none",
    backgroundColor: theme.palette.background.paper,
    boxShadow:
      "0px 0px 8px rgba(0, 0, 0, 0.08), 0px 16px 16px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    padding: theme.spacing(6),
  },
  modal: {
    //   zIndex: "1301 !important",
  },
}))
