// @ts-nocheck

import React, { useState } from "react"

import { components } from "react-select"
import { Async } from "react-select-virtualized"

import { getCouriersByServiceId } from "../../api/common"
import { SvgIcon, Box, Typography } from "@material-ui/core"
import { Clear, Search } from "@material-ui/icons"
import { defaultSelectStyles, CustomSelectProps } from "./FTLSelect"

type Props = { serviceId: string } & CustomSelectProps

const fetchOptions = (inputValue, callback, serviceId) => {
  callback(
    getCouriersByServiceId(inputValue, serviceId)
      .then(({ data }) => {
        const { items, count } = data?.result
        return items.map((item) => {
          return { value: item.id, label: item.fullName }
        })
      })
      .catch((error) => {})
  )
}

export const VirtualizedSelectNew = ({
  placeholder = "Не выбрано",
  serviceId,
  noOptionsMessage = () => "Нет данных",
  label,
  isDisabled,
  isError,
  required,
  onChange,
  value,
  ...otherProps
}: Props) => {
  const enableRequired = !isDisabled && required
  const [_value, setValue] = useState(value ? value : null)
  return (
    <Box>
      {label && (
        <Box mb={2}>
          <Typography variant="h5" style={{ color: "#627084" }}>
            {label}
            {enableRequired && <span style={{ color: "#e6360f" }}>*</span>}
          </Typography>
        </Box>
      )}
      <Async
        loadOptions={(inputValue, cb) =>
          fetchOptions(inputValue, cb, serviceId)
        }
        maxHeight={200}
        value={value}
        onChange={onChange}
        // onChange={(value) => {
        //   setValue(value)
        // }}
        optionHeight={32}
        styles={{
          // menuList: (provided, state) => {
          //   return { height: "195px" }
          // },
          menu: (provided, state) => {
            return {
              ...provided,
              zIndex: 2,
              "& > div > div": {
                // maxHeight: "200px",
                zIndex: 2,
                overflow: "hidden",
              },
            }
          },
        }}
        components={{
          // ...CustomComponents,
          DropdownIndicator: (props, d) => {
            return (
              <Search
                color="secondary"
                fontSize="small"
                style={{
                  position: "relative",
                  right: "10px",
                  marginLeft: "15px",
                  // cursor: "pointer",
                }}
              />
            )
          },
          IndicatorSeparator: () => null,
          ClearIndicator: (props) => {
            const {
              getStyles,
              innerProps: { ref, ...restInnerProps },
            } = props
            return (
              <SvgIcon
                {...restInnerProps}
                ref={ref}
                style={{ cursor: "pointer" }}
              >
                <path
                  fill-rule="evenodd"
                  clipRule="evenodd"
                  d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM14.8292 9.17543C15.1221 9.46832 15.1221 9.9432 14.8292 10.2361L13.0653 11.9999L14.8331 13.7677C15.126 14.0606 15.126 14.5355 14.8331 14.8283C14.5402 15.1212 14.0653 15.1212 13.7724 14.8283L12.0047 13.0606L10.233 14.8323C9.94007 15.1252 9.4652 15.1252 9.17231 14.8323C8.87941 14.5394 8.87941 14.0645 9.17231 13.7716L10.944 11.9999L9.17965 10.2356C8.88676 9.94267 8.88676 9.46779 9.17965 9.1749C9.47255 8.88201 9.94742 8.88201 10.2403 9.1749L12.0047 10.9393L13.7685 9.17543C14.0614 8.88254 14.5363 8.88254 14.8292 9.17543Z"
                  fill="#A9B1BC"
                />
              </SvgIcon>
            )
          },

          MultiValueRemove: (props) => {
            return (
              <components.MultiValueRemove {...props}>
                <Clear
                  color="secondary"
                  style={{ width: "16px", height: "16px" }}
                />
              </components.MultiValueRemove>
            )
          },
        }}
        noOptionsMessage={noOptionsMessage}
        placeholder={placeholder}
        {...otherProps}
      />
    </Box>
  )
}
