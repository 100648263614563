import React from "react"
import { Box } from "@material-ui/core"
import { FTLTab, FTLTabs } from "../../components/ui/FTLTabs"
import { PersonalDataTab } from "../../layouts/Users/PersonalDataTab"
import { ContactDataTab } from "../../layouts/Users/ContactDataTab"
import { SecurityTab } from "../../layouts/Users/SecurityTab"
import { FTLToolbar } from "../../components/ui/FTLToolbar"

export const NewUserPage = () => {
  const [tabIndex, setTabIndex] = React.useState(0)
  return (
    <Box>
      <FTLTabs
        value={tabIndex}
        onChange={(e, newValue) => {
          setTabIndex(newValue)
        }}
      >
        <FTLTab label="Личные данные" />
        <FTLTab label="Контактные данные" />
        <FTLTab label="Безопасность" />
      </FTLTabs>
      {tabIndex === 0 && <PersonalDataTab />}
      {tabIndex === 1 && <ContactDataTab />}
      {tabIndex === 2 && <SecurityTab />}
      {/* // TODO вынести в компонент  */}
      <FTLToolbar
        currentTabIndex={tabIndex}
        onSaveMessage="Создать"
        goToNextTab={() => {
          setTabIndex(tabIndex + 1)
        }}
        goToPrevTab={() => {
          setTabIndex(tabIndex - 1)
        }}
      />
    </Box>
  )
}
