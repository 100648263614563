import React, { useState } from "react"
import {
  Box,
  Typography,
  Avatar,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Modal,
  withStyles,
  makeStyles,
  ClickAwayListener,
} from "@material-ui/core"
import Cookies from "js-cookie"
import Logo from "../assets/img/icons/FTLLogo.svg"
import { useHistory, Link } from "react-router-dom"
import { FTLModal } from "./ui/FTLModal"
import { typography } from "../shared/theme"
import { FTLButton } from "./ui/FTLButton"

import Placeholder from "../assets/img/icons/ic_avatar_placeholder.svg"

export const Header = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [exitModalIsOpen, setExitModalIsOpen] = useState(false)
  const history = useHistory()

  const classes = useStyles()

  const handleClickOnMenuItem = (callback?: (...args: any) => void) => {
    setMenuIsOpen(!menuIsOpen)
    if (callback) callback()
  }

  const handleClickAway = () => {
    setMenuIsOpen(false)
  }

  return (
    <Box
      component="header"
      display="flex"
      padding="16px 28px 16px 24px"
      maxHeight={56}
      alignItems="center"
      // className={}
      style={{
        borderBottom: "1px solid #EBEDF0",
        position: "relative",
        backgroundColor: "#fff",
        zIndex: 1302,
        // width: "100%",
        // top: 0,
      }}
    >
      <Box display="flex" alignItems="center">
        <Link to="/profile" style={{ display: "flex" }}>
          <img src={Logo} className={classes.logo} alt="logo" />
          <Box ml={4}>
            <Typography variant="h2">Tech Admin</Typography>
          </Box>
        </Link>
      </Box>

      <Box margin="0 0 0 auto" style={{ position: "relative" }}>
        <Avatar
          style={{ width: "24px", height: "24px" }}
          onClick={() => setMenuIsOpen(!menuIsOpen)}
        >
          <img
            src={Placeholder}
            alt="avatar placeholder"
            style={{
              width: "24px",
              height: "24px",
            }}
          />
        </Avatar>
        {menuIsOpen && (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Box
              component={Paper}
              zIndex="tooltip"
              className={classes.listContainer}
            >
              <List>
                <ListItem button className={classes.listItem}>
                  <ListItemText
                    className={classes.listItemText}
                    onClick={() =>
                      handleClickOnMenuItem(() =>
                        history.push("/profile", { tabIndex: 0 })
                      )
                    }
                  >
                    Личные данные
                  </ListItemText>
                </ListItem>
                <ListItem
                  className={classes.listItem}
                  button
                  onClick={() =>
                    handleClickOnMenuItem(() =>
                      history.push("/profile", { tabIndex: 1 })
                    )
                  }
                >
                  <ListItemText className={classes.listItemText}>
                    Контактные данные
                  </ListItemText>
                </ListItem>
                <ListItem
                  className={classes.listItem}
                  button
                  onClick={() =>
                    handleClickOnMenuItem(() =>
                      history.push("/profile", { tabIndex: 2 })
                    )
                  }
                >
                  <ListItemText className={classes.listItemText}>
                    Безопасность
                  </ListItemText>
                </ListItem>
              </List>
              <Divider />
              <List>
                <ListItem
                  button
                  className={classes.listItem}
                  onClick={() =>
                    handleClickOnMenuItem(() =>
                      setExitModalIsOpen(!exitModalIsOpen)
                    )
                  }
                >
                  <ListItemText className={classes.listItemText}>
                    Выйти
                  </ListItemText>
                </ListItem>
              </List>
            </Box>
          </ClickAwayListener>
        )}
        <FTLModal open={exitModalIsOpen}>
          <Typography variant="h2">Смена аккаунта</Typography>
          <Box mt={4}>
            <Typography style={{ ...typography.unsupportedSemanticTags.p3 }}>
              Вы действительно хотите выйти из данной учётной записи?
            </Typography>
          </Box>
          <Box mt={3} display="flex" justifyContent="flex-end">
            <Box>
              <FTLButton
                onClick={() => setExitModalIsOpen(false)}
                variant="text"
              >
                Отмена
              </FTLButton>
            </Box>
            <Box ml={2}>
              <FTLButton
                onClick={() => {
                  setExitModalIsOpen(false)
                  // TODO
                  Cookies.remove("accessToken")
                  Cookies.remove("refreshToken")
                  history.push("/")
                }}
                color="primary"
              >
                Подтвердить
              </FTLButton>
            </Box>
          </Box>
        </FTLModal>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  logo: {
    width: "24px",
    height: "24px",
  },

  listContainer: {
    minWidth: "192px",
    position: "absolute",
    top: "32px",
    zIndex: 1301,
    left: "-164px",
    boxShadow:
      "0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 4px rgba(0, 0, 0, 0.08)",
  },

  listItem: {
    padding: "8px 12px",
  },

  listItemText: {
    margin: "0",
    "& > span": {
      ...typography.unsupportedSemanticTags.listItemText,
    },
  },
}))
