import React, { useMemo, useCallback } from "react"
import { FTLTable } from "../../components/ui/Table/FTLTable"
import { TagTooltip } from "../../components/ui/TagTooltip"
import { Box, IconButton, Typography } from "@material-ui/core"
import { Edit, LockOpen } from "@material-ui/icons"
import { CellProps } from "react-table"
import { FTLButton } from "../../components"
import { useHistory } from "react-router-dom"
import { FTLSearchField } from "../../components/ui/SearchField"
import { palette } from "../../shared/theme"
import { FTLSelect } from "../../components/ui/FTLSelect"

export const UsersPage = () => {
  const history = useHistory()
  // const sortBy = (rowA: any, rowB: any) => {
  //   // console.log(rowA)
  //   return 2
  // }
  // const memoizedSort = useMemo(() => {
  //   return sortBy
  // }, [])
  const columns = useMemo(() => {
    return [
      {
        Header: "ФИО",
        accessor: "fullname",
        width: 170,
        disableSortBy: true,
      },
      {
        Header: "Имя пользователя",
        accessor: "firstName",
        width: 170,
        disableSortBy: true,

        // type: "tooltip",
        // Cell: (props: any) => {
        //   return (
        //     <TagTooltip status="new" style={{ width: "fit-content" }}>
        //       {props.cell.value}
        //     </TagTooltip>
        //   )
        // },
      },
      {
        Header: "Телефон",
        accessor: "phone",
        width: 160,
        disableSortBy: true,
      },
      {
        Header: "Роли",
        accessor: "roles",
        width: 230,
        Cell: (props: CellProps<any>) => {
          const roles = props.cell.value as string[]
          // const hasAccess = Boolean(props.cell)
          return (
            <Box display="grid" gridGap="4px">
              {roles.map((role) => (
                <Box>
                  <TagTooltip
                    style={{ width: "fit-content", color: "inherit" }}
                    status="neutral"
                  >
                    {role}
                  </TagTooltip>
                </Box>
              ))}
            </Box>
          )
        },
        disableSortBy: true,
      },
      {
        Header: "Доступ в систему",
        accessor: "access",
        Cell: (props: any) => {
          const hasAccess = Boolean(props.cell.value)
          return (
            <TagTooltip
              status={hasAccess ? "success" : "error"}
              style={{
                width: "fit-content",
              }}
              // isDisabled={!hasAccess}
            >
              {hasAccess ? "Активен" : "Заблокирован"}
            </TagTooltip>
          )
        },
        width: 130,
        disableSortBy: true,
      },
      {
        Header: "Создан",
        accessor: "date",
        align: "right",
        width: 100,
        // defaultCanSort: true,
        canSort: true,
        // sortType: memoizedSort,
      },
      {
        Header: "",
        accessor: "action",
        width: 80,
        disableSortBy: true,
      },
    ]
  }, [])

  const data = useMemo(() => {
    return [
      {
        fullname: "Константинопольский Константин Константинович",
        firstName: "konstkonst@foodtechlab.com",
        phone: "+7 (999) 999-99-99",
        roles: ["Neutral", "Neutral"],
        access: true,
        date: "17:30 20.02.2020",
        action: (
          <Box display="flex">
            <IconButton size="small">
              <LockOpen color="secondary" />
            </IconButton>
            <IconButton size="small">
              <Edit color="secondary" />
            </IconButton>
          </Box>
        ),
      },
      {
        fullname: "Константинопольский Константин Константинович",
        firstName: "konstkonst@foodtechlab.com",
        phone: "+7 (999) 999-99-99",
        roles: ["Neutral", "Neutral"],
        access: false,
        date: "17:30 19.02.2020",
        action: (
          <Box display="flex">
            <IconButton size="small">
              <LockOpen color="secondary" />
            </IconButton>
            <IconButton size="small">
              <Edit color="secondary" />
            </IconButton>
          </Box>
        ),
      },
      {
        fullname: "Константинопольский Константин Константинович",
        firstName: "konstkonst@foodtechlab.com",
        phone: "—",
        roles: ["Neutral", "Neutral"],
        access: false,
        date: "17:30 18.02.2020",
        action: (
          <Box display="flex">
            <IconButton size="small">
              <LockOpen color="secondary" />
            </IconButton>
            <IconButton size="small">
              <Edit color="secondary" />
            </IconButton>
          </Box>
        ),
      },
      {
        fullname: "Иванов Иван",
        firstName: "ivanov@foodtechlab.com",
        phone: "+7 (999) 999-99-99",
        roles: ["Neutral", "Neutral"],
        access: true,
        date: "17:30 17.02.2020",
        action: (
          <Box display="flex">
            <IconButton size="small">
              <LockOpen color="secondary" />
            </IconButton>
            <IconButton size="small">
              <Edit color="secondary" />
            </IconButton>
          </Box>
        ),
      },
    ]
  }, [])
  return (
    <Box>
      <Box ml={2}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h1">Пользователи системы</Typography>
          <FTLButton
            color="primary"
            onClick={() => {
              history.push("/users/new") //!  FIX ME
            }}
          >
            Добавить
          </FTLButton>
        </Box>
        <Box
          mt={6}
          display="grid"
          gridGap="16px"
          maxHeight="32px"
          gridTemplateColumns="200px 160px 160px"
        >
          <FTLSearchField placeholder="ФИО или email" />
          {/* <FTLSelect
            style={{ height: "32px", marginTop: "0px" }}
            id="select-platform"
            items={[
              { label: "Neutral", value: "neutral" },
              // { label: "iOS", value: "iOS" },
            ]}
            // items={["Neutral"]}
            // placeholder="test"
            size="small"
            // value=""
            defaultPlaceholder="Роль"
          /> */}
          {/* <Select
            options={[
              { label: "Neutral", value: "neutral" },
              // { label: "iOS", value: "iOS" },
            ]}
          /> */}
          <FTLSelect
            options={[
              { label: "Активен", value: "isActive" },
              { label: "Заблокирован", value: "isDeactive" },
            ]}
            size="small"
            placeholder="Активность"
            isClearable
          />
          <FTLSelect
            options={[{ label: "Neutral", value: "neutral" }]}
            size="small"
            placeholder="Роль"
            isClearable
          />
          {/* <FTLSelect
            style={{ height: "32px", marginTop: "0px" }}
            id="select-service"
            items={[
              { label: "Активен", value: "isActive" },
              { label: "Заблокирован", value: "isDeactive" },
            ]}
            // items={["Активен", "Заблокирован"]}
            // placeholder="test"
            // value=""
            size="small"
            defaultPlaceholder="Активность"
          /> */}
        </Box>
      </Box>
      <Box mt={4}>
        <FTLTable pageCount={1} columns={columns} data={data} />
      </Box>
    </Box>
  )
}
