import React from "react"
import { TextField, makeStyles, TextFieldProps } from "@material-ui/core"
import { typography } from "../../shared/theme"

type Props = TextFieldProps

export const FTLTextField = ({
  style,
  size,
  InputProps = {},
  ...other
}: Props) => {
  const classes = useStyles()
  return (
    <TextField
      classes={{
        root: classes.root,
      }}
      style={style}
      // style={{width ? {width} : {}}}
      InputLabelProps={{
        variant: "standard",
        classes: {
          root: classes.label,
          asterisk: classes.asterisk,
          // focused: classes.focused,
          error: classes.error,
        },
      }}
      InputProps={{
        classes: {
          root: `${classes.inputRoot} ${size === "small" && classes.small}`,
          error: classes.error,
          focused: classes.inputFocused,
          notchedOutline: classes.notchedOutline,
          adornedEnd: classes.icon,
          // inputAdornedEnd: classes.icon,
        },
        ...InputProps,
      }}
      {...other}
      variant="outlined"
    />
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: "240px"
  },
  label: {
    position: "static",
    marginBottom: "8px",
    transform: "none",
    ...theme.typography.h5,
    color: `${theme.palette.text.tertiary} !important`,
    whiteSpace: "nowrap",
  },

  inputRoot: {
    "& > input": {
      padding: "11.7px 14px",
      zIndex: 1,
      "&:-webkit-autofill, &:-internal-autofill-selected": {
        boxShadow: `0 0 0 2px ${theme.palette.text.borderColor}`,
      },
      ...typography.unsupportedSemanticTags.listItemText,
    },
    "&:not($inputFocused, $error):hover > fieldset": {
      backgroundColor: "#EBEDF0 !important",
    },
    // "&:not($inputFocused):hover > fieldset": {
    //   backgroundColor: "#EBEDF0 !important",
    // },
    "&$error > fieldset": {
      borderWidth: "2px",
      borderColor: `${theme.palette.text.error} !important`,
    },
    // "& > fieldset": { borderColor: "#C4CBD4" },
    "&$inputFocused:not($error) > fieldset": {
      borderColor: `${theme.palette.text.borderColor}`,
    },
    "&:not($error):hover > fieldset, &$notchedOutline": {
      borderWidth: "2px",
      borderColor: `${theme.palette.text.borderColor} `,
    },
    "&:not($inputFocused):hover > fieldset": {
      borderColor: "#C4CBD4 !important",
      backgroundColor: "#EBEDF0",
    },
    "&:not($inputFocused) > fieldset": {
      backgroundColor: "#F9FAFA",
    },
    // '& > fieldset'
    // "&:not($error), hover > $notchedOutline": {
    //   borderColor: `${theme.palette.text.borderColor} `,
    // },
    // "& > fieldset > legend": {
    //   visibility: "hidden",
    // },
    "& > fieldset > legend": {
      display: "none",
    },
  },
  small: {
    "&$inputRoot > input": {
      padding: "7.7px",
    },
    "& > fieldset": {
      top: 0,
    },
  },
  icon: {
    paddingRight: "0",
    "& div": {
      position: "absolute",
      right: "8px",
    },
    "&  button": {
      padding: "0",
    },
    "&  svg": {
      zIndex: 1,
    },
  },
  error: {
    // "& > fieldset": {
    //   borderWidth: "2px",
    //   borderColor: `${theme.palette.text.error}`,
    // },
  },
  notchedOutline: {
    backgroundColor: "#fff",
    top: 0,
    // backgroundColor: "#F9FAFA",
    // "&:not($inputFocused)": {
    //   backgroundColor: "#F9FAFA",
    // },

    borderColor: "#C4CBD4",
    // "&,  &.Mui-Focused, & $inputFocused": {
    //   borderWidth: "2px",
    //   borderColor: `${theme.palette.text.borderColor}`,
    // },
  },
  inputFocused: {
    // backgroundColor: "#fff",
    // borderWidth: "2px",
    // borderColor: `${theme.palette.text.borderColor} !important`,
  },
  asterisk: { color: theme.palette.text.error },
}))
