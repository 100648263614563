import React from "react"
import { Container, Typography, Box } from "@material-ui/core"
import { typography } from "../shared/theme"
import { FTLButton } from "../components"
import { useHistory } from "react-router-dom"

export const PermissionDeniedPage = () => {
  const history = useHistory()
  return (
    <Container maxWidth="xs">
      <Box display="flex" mt={4} alignItems="center" flexDirection="column">
        <Box>
          <Typography variant="h1">Доступ запрещён</Typography>
        </Box>
        <Box mt={3} maxWidth="500px">
          <Typography
            align="center"
            style={{ ...typography.unsupportedSemanticTags.p3 }}
          >
            Недостаточно прав для доступа. Обратитесь к администратору сервиса
            или вернитесь в предыдущий раздел
          </Typography>
        </Box>
        <Box mt={6}>
          <FTLButton color="primary" onClick={() => history.goBack()}>
            Вернуться назад
          </FTLButton>
        </Box>
      </Box>
    </Container>
  )
}
