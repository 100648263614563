import React from "react"

import styled from "styled-components"
import { typography, palette } from "../../../shared/theme"
import { IconButton } from "@material-ui/core"
import { SvgIconComponent } from "@material-ui/icons"

type TableCellProps = React.HTMLProps<HTMLButtonElement>

type Props = {
  action?: {
    icon: SvgIconComponent
    onClick?: (e: React.MouseEvent) => void
  }
  children?: React.ReactNode
}

// export const FTLTableCell = React.forwardRef<HTMLTableCellElement, Props>(
//   (props, ref) => (
//     <TableCell ref={ref} {...props}>
//       {props.children}
//     </TableCell>
//   )
// )

const renderActionButton = (
  icon: SvgIconComponent,
  onClick?: (e: React.MouseEvent) => void
) => {
  return <IconButton onClick={onClick}>{icon}</IconButton>
}

// export const FTLTableCell = ({ action, children }: Props) => {
//   // React.Children.map(children, (child) => {
//   //   console.log(children)
//   // })
//   return (
//     <StyledFTLTableCell>
//       {/* {action ? renderActionButton(action.icon, action.onClick) : children} */}
//       {children}
//     </StyledFTLTableCell>
//   )
// }

export const FTLTableCell = styled.td<{
  textAlign?: "left" | "right" | "center" | "justify"
  action?: boolean
  isDisabled?: boolean
  isFocusable?: boolean
}>`
  padding: 15px 8px 8px 8px;
  vertical-align: top;
  & > .MuiIconButton-root {
    padding: 0;
  }
  text-align: ${(props) => props.textAlign};
  color: ${(props) =>
    props.isDisabled ? palette.text.disable : palette.text.primary};
  outline: none;

  ${typography.unsupportedSemanticTags.listItemText};
`

export const FTLTableHeadCell = styled.th<{
  textAlign?: "left" | "right" | "justify" | "center"
  minWidth?: number
  isFocusable?: boolean
}>`
  color: #617084;
  padding: 8px;
  text-align: ${(props) => props.textAlign || "left"};
  min-width: ${(props) => props.minWidth}px;
  white-space: nowrap;
  outline: none;
  &:active {
    background: #f0f1f1;
  }
  ${(props) =>
    props.isFocusable &&
    `
    &:hover {
      background-color: #f5f5f6;
    }
    &:focus {
    background-color: #fff;
    outline: 2px solid #66a6ff;
  }`}
  ${typography.supportedTags.h5};
`
