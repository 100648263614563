import React from "react"
import { Box, Typography } from "@material-ui/core"
import { FTLTextField, FTLButton } from "../../components"

export const SecurityTab = () => (
  <Box mt={6}>
    <Typography variant="h3">Изменить пароль</Typography>
    <Box maxWidth={360} mt={6} display="grid" gridGap={24}>
      <FTLTextField label="Текущий пароль" type="password" />
      <FTLTextField label="Новый пароль" type="password" />
      <FTLTextField label="Подтверждение пароля" type="password" />
      {/* <Box display="flex">
        <FTLButton color="primary">Сохранить</FTLButton>
      </Box> */}
    </Box>
  </Box>
)
