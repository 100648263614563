import React, { useReducer } from "react"
import { SmsServicesParamsPage } from "./Params"
import { SmsServicesCodesPage } from "./Codes"

export type BreadcrumbType = { to: string; label: string }

type BreadcrumbsState = BreadcrumbType[]

const breadcrumbsInitialState: BreadcrumbsState = [
  { to: "/sms-service/", label: "SMS-сервисы" },
  // { to: "/sms-service/", label: "Параметры SMS-сервисов" },
  // { to: "/sms-services", label: "Новый сервис" },
]

export type BreacrumbsAction =
  | {
      type: "ADD_BREADCRUMB"
      breadcrumb: BreadcrumbType
    }
  | {
      type: "REMOVE_BREADCRUMB"
      breadcrumb: BreadcrumbType
    }

export const breadcrumbsReducer = (
  state: typeof breadcrumbsInitialState,
  action: BreacrumbsAction
): BreadcrumbsState => {
  switch (action.type) {
    case "ADD_BREADCRUMB": {
      return [...state, action.breadcrumb]
    }
    case "REMOVE_BREADCRUMB": {
      // const itemIsExist = state.indexOf(action.breadcrumb)
      return [...state]
    }
    default:
      throw Error("unexpected breadcrumbs action")
  }
}

type Props = {
  page: "params" | "codes"
}

export const SmsServicesPage = ({ page }: Props) => {
  const [breadcrumbsItems, dispatchBreadcrumbsAction] = useReducer(
    breadcrumbsReducer,
    breadcrumbsInitialState
  )

  const addBreadcrumb = (breadcrumb: BreadcrumbType): void => {
    dispatchBreadcrumbsAction({ type: "ADD_BREADCRUMB", breadcrumb })
    // console.log(history)
  }

  return page === "params" ? (
    <SmsServicesParamsPage
      // breadcrumbs={[
      //   ...breadcrumbsItems,
      //   { to: "/sms-service/", label: "Параметры SMS-сервисов" },
      // ]}
      title="Параметры SMS сервисов"
      // dispatchBreadcrumbsAction={dispatchBreadcrumbsAction}
    />
  ) : (
    <SmsServicesCodesPage
      // breadcrumbs={breadcrumbsItems}
      title="Коды авторизации"
      // dispatchBreadcrumbsAction={dispatchBreadcrumbsAction}
    />
  )
}
