import React from "react"
import { FormControl, Box } from "@material-ui/core"
import { FTLTextField, FTLButton } from "../../components"
import { FTLSelect } from "../../components/ui/FTLSelect"

export const MainSettingsTab = () => {
  return (
    <Box mt={6} maxWidth={360}>
      <Box>
        <FTLTextField required label="Наименование" fullWidth />
      </Box>
      {/* <FTLTextField  label="Тип сервиса" /> */}
      <Box mt={6}>
        <FTLSelect
          style={{ width: "120px" }}
          label="Тип сервиса"
          options={[{ label: "SMS", value: "sms" }]}
          isClearable
          id="type-service-select"
        />
      </Box>
      <Box mt={6}>
        <FTLTextField
          fullWidth
          required
          label="URL для получения конфигурации"
        />
      </Box>
      <Box mt={4}>
        <FTLButton disabled>Сохранить</FTLButton>
      </Box>
    </Box>
  )
}
