import React, { useState } from "react"
import { Box, Avatar, Typography } from "@material-ui/core"
import { FTLTextField, FTLButton } from "../../components"
import Placeholder from "../../assets/img/icons/ic_avatar_placeholder.svg"
import { typography } from "../../shared/theme"
import { FTLSelect } from "../../components/ui/FTLSelect"

export const PersonalDataTab = () => {
  const [roles, setRoles] = useState([
    { label: "Технический администратор", value: "tech-admin" },
    { label: "Администратор", value: "admin" },
  ])
  const [access, setAccess] = useState([
    { label: "Активный", value: "active" },
    { label: "Неактивный", value: "deactive" },
  ])
  return (
    <Box
      // component="form"
      mt={6}
      // className={classes.formContainer}
      display="flex"
    >
      <Box width={360} display="grid" gridGap={24}>
        <FTLTextField
          required
          label="Фамилия"
          name="lastName"
          // value={lastName}
          // onChange={(e) => {
          //   dispatch({
          //     type: "CHANGE_LAST_NAME",
          //     payload: { value: e.target.value },
          //   })
          // }}
        />
        <FTLTextField
          required
          name="firstName"
          label="Имя"
          // value={firstName}
          // onChange={(e) =>
          //   dispatch({
          //     type: "CHANGE_FIRST_NAME",
          //     payload: { value: e.target.value },
          //   })
          // }
        />
        <FTLTextField
          label="Отчество"
          name="middleName"
          // value={middleName}
          // onChange={(e) =>
          //   dispatch({
          //     type: "CHANGE_MIDDLE_NAME",
          //     payload: { value: e.target.value },
          //   })
          // }
        />
        <Box>
          <Typography variant="h5" style={{ color: "#627084" }}>
            Роли
          </Typography>
          <Box mt={2}>
            <FTLSelect
              isMulti
              placeholder="Роли пользователя"
              options={roles}
              // components={{}}
              noOptionsMessage={() => "Нет доступных ролей"}
            />
          </Box>
        </Box>
        <Box>
          <Typography variant="h5" style={{ color: "#627084" }}>
            Доступ в систему
          </Typography>
          <Box mt={2}>
            <FTLSelect options={access} defaultValue={access[0]} />
          </Box>
        </Box>

        {/* <FTLSelect
          label="Роли"
          items={[
            { label: "Технический администратор", value: "tech-admin" },
            { label: "Администратор", value: "admin" },
          ]}
          // items={["Технический администратор", "Администратор"]}
          id="roles-select"
          multiple
          fullWidth
          onChange={(e) => {
            setRoles(e.target.value as [])
          }}
          // value={["tech-admin", "admin"]}
          placeholder="Роли пользователя"
          // defaultPlaceholder="Роли пользователя"
        />

        <FTLSelect
          label="Доступ в систему"
          id="access-select"
          // items={["Активен", "Неактивен"]}
          items={[
            { label: "Активен", value: "active" },
            { label: "Неактивен", value: "deactive" },
          ]}
          value={access}
          onChange={(e) => {
            setAccess(e.target.value as { label: string; value: string })
          }}
        /> */}
      </Box>
      <Box ml="auto">
        <Avatar style={{ width: "96px", height: "96px" }}>
          <img src={Placeholder} alt="profile placeholder" />
        </Avatar>
        <FTLButton
          color="secondary"
          // variant="contained"
          style={{ marginTop: "8px" }}
        >
          Загрузить
        </FTLButton>
      </Box>
    </Box>
  )
}
