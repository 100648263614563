import React from "react"
import styled from "styled-components"
import { typography } from "../../../shared/theme"

type Props = {
  children?: React.ReactNode
  isActive?: boolean
}

// export const FTLTablePaginationButton = ({ children }: Props) => {
//   return <StyledButton>{children}</StyledButton>
// }
type ButtonProps = React.HTMLProps<HTMLButtonElement>

export const FTLTablePaginationButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & Props
>(({ children, style, isActive = false, disabled, onClick }, ref) => (
  <StyledButton
    ref={ref}
    style={style}
    onClick={onClick}
    disabled={disabled}
    isActive={isActive}
  >
    {children}
  </StyledButton>
))

const StyledButton = styled.button<{ isActive?: boolean; disabled?: boolean }>`
  border-radius: 4px;
  padding: 6px 2px;
  border: none;
  cursor: pointer;
  outline: none;
  color: #52637a;
  width: 32px;
  background-color: rgba(3, 10, 22, 0.04);
  font-family: Inter;
  &:hover {
    color: #fff;
    background-color: #52637a;
  }
  ${(props) =>
    props.isActive &&
    `
    background-color: #2D3D53;
    color: #fff;
   `}
  ${typography.unsupportedSemanticTags.buttonTextSecondary};
  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
background-color: #f5f5f6; color: #B7BEC8; `}
`
