import React from "react"
import { Box } from "@material-ui/core"
import { FTLBreadcrumbs } from "../../components/ui/FTLBreadcrumbs"
import { useLayout } from "../../shared/hooks/useLayout"
import { FTLTextField } from "../../components"
import { FTLSelect } from "../../components/ui/FTLSelect"

export const TimeZonesNewPage = () => {
  const page = useLayout({
    components: {
      Breadcrumbs: {
        items: [
          {
            label: "Часовые пояса",
            to: "/time-zones",
          },
          {
            label: "Новый часовой пояс",
            to: "#",
          },
        ],
      },
      Header: {
        title: "Новый часовой пояс",
      },
      Toolbar: { onSaveMessage: "Создать" },
    },
  })
  return (
    <Box width="360px">
      <Box>{page.Breadcrumbs}</Box>
      <Box mt={1}>{page.Header}</Box>
      <Box mt={6}>
        <FTLTextField fullWidth label="Наименование" required value={null} />
      </Box>
      <Box mt={6}>
        <FTLSelect
          label="Временная зона"
          required
          isClearable
          //   isSearchable
          placeholder="Укажите временную зону"
          options={[
            { label: "Европа/Москва", value: "moscow" },
            { label: "Европа/Саратов", value: "saratov" },
          ]}
        />
      </Box>
      {page.Toolbar}
    </Box>
  )
}
