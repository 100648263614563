import React, { useEffect, useRef } from "react"
import {
  useTable,
  Column,
  usePagination,
  Cell,
  useSortBy,
  useAsyncDebounce,
} from "react-table"
import styled from "styled-components"
import { FTLTableRow, FTLTableRowHead } from "./TableRow"
import { FTLTableHeadCell, FTLTableCell } from "./TableCell"
import { SvgIconComponent } from "@material-ui/icons"
import { IconButton, SvgIcon, Box, Typography } from "@material-ui/core"
import { FTLTableFooter } from "./FTLTableFooter"
import { TagTooltip } from "../TagTooltip"
import { FTLTableBody } from "./FTLTableBody"
type Props = {
  columns: Column[]
  // columns: Column[]
  data: any[]
  pageCount: number
  onFetchData?: (pageNumber: number, limit?: number) => void
  onFetchPage?: (...args: any) => void
  // fixedTableSizes?: number[]
  actions?: {
    IconComponent: SvgIconComponent
    onClick?: (e: React.MouseEvent) => void
  }[]
  skipPageReset?: boolean
}

const renderCell = (cell: Cell) => {
  // if(cell.column?.type === 'tooltip') {
  return (
    <TagTooltip status="info">
      {cell.render("Cell", { type: "tooltip" })}
    </TagTooltip>
  )
  // }
}

export const FTLTable = ({
  // fixedTableSizes,
  columns,
  data,
  actions = [],
  pageCount,
  // pageSize,
  onFetchData = (offset: number) => {},
  skipPageReset,
}: // onFetchPage = () => {},
Props) => {
  const [controlledPageIndex, setControlledPage] = React.useState(0)

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    // pageCount: _pageCount,
    state: { pageIndex, pageSize, sortBy },
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    // filters
    // pageCount,
    headerGroups,
    headers,
  } = useTable(
    {
      // useControlledState: (state) => {
      //   return React.useMemo(() => ({ ...state, page }), [state])
      // },
      // useControlledState: (state) => {
      //   return React.useMemo(
      //     () => ({
      //       ...state,
      //       pageIndex: controlledPageIndex,
      //     }),
      //     [state, controlledPageIndex]
      //   )
      // },
      autoResetPage: false,
      columns,
      data,
      defaultCanSort: false,
      disableMultiSort: false,
      manualPagination: true,
      initialState: {
        pageIndex: 0,
      },
      pageCount,
    },
    useSortBy,
    usePagination
  )

  const onFetchDataDebounced = useAsyncDebounce(onFetchData, 350)

  const skipFirstFetchFlag = useRef(true)

  useEffect(() => {
    if (!skipFirstFetchFlag.current) {
      const offset = pageIndex * pageSize
      onFetchDataDebounced(offset, pageSize)
    }
  }, [onFetchDataDebounced, pageIndex, pageSize])

  useEffect(() => {
    skipFirstFetchFlag.current = false
  }, [])

  return (
    <Box>
      <>
        <StyledTable {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <FTLTableRowHead {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => {
                  return (
                    <FTLTableHeadCell
                      // @ts-ignore
                      textAlign={column?.align ?? "left"}
                      minWidth={column.width as number}
                      isFocusable={column.canSort}
                      tabIndex={column.canSort ? index : -1}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <Box display="inline-flex">
                        {column.render("Header")}
                        {column.canSort ? (
                          // <SvgIcon>
                          <svg width="16" height="16" viewBox="0 0 16 16">
                            <path
                              d="M10 7H6L8 3L10 7Z"
                              fill={column.isSortedDesc ? "#132339" : "#A9B1BC"}
                            />
                            <path
                              d="M10 9H6L8 13L10 9Z"
                              fill={
                                column.isSorted && !column.isSortedDesc
                                  ? "#132339"
                                  : "#A9B1BC"
                              }
                            />
                          </svg>
                        ) : (
                          // <path d="M10 7H6L8 3L10 7Z" fill="#A9B1BC" />
                          // <path d="M10 9H6L8 13L10 9Z" fill="#A9B1BC" />
                          // </SvgIcon>
                          ""
                        )}
                      </Box>
                    </FTLTableHeadCell>
                  )
                })}
              </FTLTableRowHead>
            ))}
          </thead>
          <FTLTableBody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              const isDisabled =
                row.cells.filter(
                  (cell) => cell.value === false && cell.column.id === "access"
                ).length > 0
              return (
                <FTLTableRow {...row.getRowProps()} isDisabled={isDisabled}>
                  {row.cells.map((cell, index) => {
                    return (
                      <FTLTableCell
                        {...cell.getCellProps()}
                        isDisabled={isDisabled}
                        tabIndex={index}
                        // style={{
                        //   background:
                        //     cell.column.id === "access" && cell.value === false
                        //       ? "red"
                        //       : "black",
                        // }}
                        textAlign={
                          // @ts-ignore
                          cell.column?.align || cell.column.id === "action"
                            ? "right"
                            : "left"
                        }
                      >
                        <Box>{cell.render("Cell")}</Box>
                      </FTLTableCell>
                    )
                  })}
                </FTLTableRow>
              )
            })}
          </FTLTableBody>
        </StyledTable>
        <FTLTableFooter
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageCount={pageCount}
          setPageSize={setPageSize}
          gotoPage={(pageNumber) => {
            setControlledPage(pageNumber - 1)
            // console.log(pageNumber, pageIndex, controlledPageIndex)
            gotoPage(pageNumber - 1)
            // onFetchData(pageNumber)
          }}
        />
      </>
    </Box>
  )
}

export const StyledTable = styled.table<{ fixedTable?: boolean }>`
  width: 100%;
  border-collapse: collapse;
  // border-bottom: 2px solid #ebedf0;
  position: relative;
  // table-layout: ${(props) => (props.fixedTable ? "fixed" : "auto")};
  // & td {
  //   overflow: hidden;
  // }
`
