import { createStore, combine, createEvent, sample, guard } from "effector"
import * as Yup from "yup"

export const $firstName = createStore("")
export const $middleName = createStore("")
export const $lastName = createStore("")

export const formSubmitted = createEvent()
export const formDisabled = createEvent()
//Временная заглушка
export const trigger = createEvent()

export const $form = combine({
  firstName: $firstName,
  middleName: $middleName,
  lastName: $lastName,
})

export const target = sample({
  source: $form,
  clock: trigger,
})

// target.watch((obj) => {
//   console.log(obj)
// })

export const CourierSchema = Yup.object({
  firstName: Yup.string().required(),
  middleName: Yup.string().notRequired(),
  lastName: Yup.string().required(),
  countryId: Yup.string().required(),
  cityId: Yup.string().required(),
  phoneNumber: Yup.string()
    .matches(
      /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/gi,
      "Номер телефона не валиден"
    )
    .required(),
  // phoneNumber: Yup.number()
  //   .notRequired()
  //   .min(11, "Номер телефона должен состоять как минимум из 11 символов")
  //   .nullable(true),
  serviceId: Yup.string().required().nullable(true),
  externalSystems: Yup.array(
    Yup.object({
      externalSystemSyncAccountId: Yup.string(),
      id: Yup.string(),
    }).notRequired()
  ),
  id: Yup.string().notRequired(),
})

export type CourierState = {
  firstName: string
  middleName: string
  lastName: string
  phoneNumber: number | string | null

  countryId: string | null
  cityId: string | null
  serviceId: string | null

  externalSystems: {
    externalSystemSyncAccountId?: string
    id?: string
  }[]
}
