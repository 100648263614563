import React from "react"
import { FTLTableRow } from "./TableRow"
import { FTLTableCell } from "./TableCell"
import styled from "styled-components"

type Props = {
  columns: any[]
  data: any[]
  getFieldValue: any
}

// export const FTLTableBody = ({ data, columns, getFieldValue }: Props) => {
//   // const mappedColumns = columns.map(column => column)
//   //   console.log(getFieldValue(data, columns[0]))
//   //   console.log(columns)
//   const mapped = data.map((item) => {
//     // const value = columns
//     console.log(columns)
//   })

//   // return null
//   // <FTLTableRow>
//   //   {data.map(item => )}
//   //   {/* {renderColumns()} */}
//   //   {/* <FTLTableCell>{column[field]}</FTLTableCell> */}
//   //   {/* {actions?.map((action) => (
//   //            <FTLTableCell textAlign="right">
//   //              {renderAction(action)}
//   //            </FTLTableCell>
//   //          ))} */}
//   // </FTLTableRow>
// }

export const FTLTableBody = styled.tbody<{ [P: string]: any }>`
  position: relative;
  &:before,
  &:after {
    content: "@";
    display: block;
    line-height: 4px;
    text-indent: -99999px;
  }
  & > tr:last-child {
    box-shadow: 0 4px 0 -2px #ebedf0;
  }
`
