import React, { useState } from "react"
import {
  TextFieldProps,
  InputAdornment,
  IconButton,
  makeStyles,
} from "@material-ui/core"
import { FTLTextField } from "../index"
import { Visibility, VisibilityOutlined } from "@material-ui/icons/"
import { palette } from "../../shared/theme"

type Props = { isVisible?: boolean; InputProps?: any } & TextFieldProps
export const FTLPasswordField = ({
  isVisible,
  InputProps,
  ...other
}: Props) => {
  const [value, setValue] = useState<string | null>(null)
  const [areVisible, setIsVisible] = useState(isVisible ? true : false)

  const handleClickShowPassword = () => setIsVisible(!areVisible)
  const classes = useStyles()

  return (
    <FTLTextField
      type={areVisible ? "text" : "password"}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      classes={{}}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              tabIndex={-1}
              // disableFocusRipple
              className={classes.icon}
              onClick={handleClickShowPassword}
            >
              {areVisible ? (
                <Visibility htmlColor={palette.icon.secondary} />
              ) : (
                <VisibilityOutlined htmlColor={palette.icon.secondary} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...other}
    />
  )
}

const useStyles = makeStyles({
  icon: {
    "&:hover": {
      backgroundColor: "initial",
    },
  },
})
