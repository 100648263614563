import React, { useState, useRef, RefObject } from "react"
import Select, {
  GroupedOptionsType,
  components,
  ActionMeta,
  Styles,
  OptionsType,
} from "react-select"
import { palette, typography } from "../../shared/theme"
import { Box, Typography, SvgIcon } from "@material-ui/core"
import { borderRadius } from "react-select/src/theme"
import { TagTooltip } from "./TagTooltip"
import { ArrowBack, Clear } from "@material-ui/icons"
// import { ReactSelectProps } from "./react-select"

type OptionType = {
  value: string
  label: string
}

export const defaultSelectStyles = (
  size: "small" | "default" = "default",
  isError?: boolean
): Partial<Styles> => ({
  indicatorsContainer: (provided) => ({
    ...provided,
    height: size === "small" ? "28px" : "36px ",
    // minHeight: size === "small" ? "32px" : "40px",
    // maxHeight: size === "small" ? "32px" : "auto",
    "& > div": {
      color: palette.icon.secondary,
      "&:hover": { color: palette.icon.secondary },
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    minHeight: size === "default" ? "36px" : "32px",
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
  }),
  control: (provided, state) => ({
    ...provided,
    borderColor:
      state.isFocused || state.hasValue
        ? "transparent"
        : palette.border.textField,
    boxShadow: isError
      ? "0 0 0 2px #E6360F"
      : state.isFocused || state.hasValue
      ? `0 0 0 2px #66A6FF`
      : "none",
    minHeight: size === "default" ? "40px" : "32px",
    maxHeight: size === "small" ? "32px" : "auto",
    // height: size === "default" ? "40px" : "32px",
    backgroundColor: state.isFocused || state.hasValue ? "#fff" : "#F9FAFA",
    "&:hover": {
      backgroundColor:
        state.isFocused || state.hasValue ? "#fff" : palette.text.hover,
    },
  }),
  multiValue: (provided, state) => ({
    ...provided,
    fontSize: "100%",
    backgroundColor: "#e5e7e8",
    borderRadius: "4px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    ...typography.unsupportedSemanticTags.tooltip,
    padding: "2px 4px",
    color: palette.text.secondary,
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    // display: "flex",
    // alignItems: "center",
    // fontSize: "100%",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    ...typography.unsupportedSemanticTags.listItemText,
    color: palette.text.placeholder,
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 2,
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: "200px",
    "& > div": {
      color: "#132339",
      padding: "6px 12px",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    ...typography.unsupportedSemanticTags.listItemText,
    backgroundColor: state.isFocused ? "#f5f5f6" : "#fff",
  }),
})

// type Props = {
//   label?: string
//   size?: "small" | "default"
//   isError?: boolean
// } & Select["props"]
//  & Exclude<Select["props"], "options"> & { options?: OptionsType }

export type CustomSelectProps = {
  label?: string
  size?: "small" | "default"
  isError?: boolean
  // isClearable?: boolean
  // options?: OptionType[]
  required?: boolean
} & Select["props"]

const noop = () => {}

export const FTLSelect = ({
  options,
  label,
  isError,
  isDisabled,
  onChange,
  isSearchable = false,
  isClearable,
  required,
  components: CustomComponents,
  value,
  size = "default",
  placeholder = "Не выбрано",
  noOptionsMessage = () => "Нет данных",
  defaultValue,
  ...otherProps
}: CustomSelectProps) => {
  const customStyles = {
    indicatorsContainer: () => ({
      "& > div": {
        color: "red",
      },
    }),
  }

  // const handleOnChange = (
  //   value: typeof controlledValue,
  //   actionMeta: ActionMeta<typeof value>
  // ) => {
  //   if (onChange) {
  //     // @ts-ignore
  //     onChange(value, actionMeta)
  //   }
  //   setValue(value)
  // }

  // const [controlledValue, setValue] = useState(
  //   value || { label: "", value: "" }
  // )
  const enableRequired = !isDisabled && required
  const selectRef = useRef<any | null>(null)
  return (
    <Box>
      {label && (
        <Box mb={2}>
          <Typography variant="h5" style={{ color: "#627084" }}>
            {label}
            {enableRequired && <span style={{ color: "#e6360f" }}>*</span>}
          </Typography>
        </Box>
      )}
      <Select
        ref={selectRef}
        options={options}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        isClearable={isClearable}
        defaultValue={defaultValue}
        value={value}
        //@ts-ignore
        onChange={onChange}
        // input
        components={{
          ...CustomComponents,
          IndicatorSeparator: () => null,
          ClearIndicator: (props) => {
            const {
              getStyles,
              innerProps: { ref, ...restInnerProps },
            } = props
            return (
              <SvgIcon
                {...restInnerProps}
                ref={ref}
                // style={getStyles("clearIndicator", props)}
              >
                <path
                  fill-rule="evenodd"
                  clipRule="evenodd"
                  d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM14.8292 9.17543C15.1221 9.46832 15.1221 9.9432 14.8292 10.2361L13.0653 11.9999L14.8331 13.7677C15.126 14.0606 15.126 14.5355 14.8331 14.8283C14.5402 15.1212 14.0653 15.1212 13.7724 14.8283L12.0047 13.0606L10.233 14.8323C9.94007 15.1252 9.4652 15.1252 9.17231 14.8323C8.87941 14.5394 8.87941 14.0645 9.17231 13.7716L10.944 11.9999L9.17965 10.2356C8.88676 9.94267 8.88676 9.46779 9.17965 9.1749C9.47255 8.88201 9.94742 8.88201 10.2403 9.1749L12.0047 10.9393L13.7685 9.17543C14.0614 8.88254 14.5363 8.88254 14.8292 9.17543Z"
                  fill="#A9B1BC"
                />
              </SvgIcon>
            )
          },

          MultiValueRemove: (props) => {
            return (
              <components.MultiValueRemove {...props}>
                <Clear
                  color="secondary"
                  style={{ width: "16px", height: "16px" }}
                />
              </components.MultiValueRemove>
            )
          },
        }}
        noOptionsMessage={noOptionsMessage}
        placeholder={placeholder}
        styles={defaultSelectStyles(size, isError)}
        {...otherProps}
      />
      {/* {enableRequired && (
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{
            opacity: 0,
            width: "0px",
            height: 0,
            position: "absolute",
          }}
          // @ts-ignore
          value={controlledValue}
          onChange={noop}
          onFocus={() => {
            if (selectRef.current) {
              selectRef.current.focus()
            }
          }}
          required
        />
      )} */}
    </Box>
  )
}
