// import { createEffect, attach, createStore } from "effector"
import axios, { AxiosResponse } from "axios"
import { getCookie, deleteCookie, once } from "../shared/common"
import { nanoid } from "@reduxjs/toolkit"
import { history } from "../App"
import Cookies from "js-cookie"
// export const $BASE_URL = createStore(
//   process.env.NODE_ENV === "development"
//     ? "https://ftl-tech-admin-api-stg1.sushivesla.net/"
//     : undefined
// )

// interface Request {
//   path: string
//   uri: string
//   method: "POST" | "GET" | "PUT"
//   body?: object
//   headers?: Record<string, string>
// }

// const requestFx = createEffect<Request, any, Error>({
//   handler: async ({ path, method, headers, ...config }) => {
//     const body = config.body ? JSON.stringify(config.body) : undefined
//     const response = await fetch(path, { method, body, headers })

//     if (!response.ok) {
//       // throw new NetworkError(await response.json())
//     }
//     return response.json()
//   },
// })

// const requestInternalFx = attach({
//   source: $BASE_URL,
//   effect: requestFx,
//   mapParams: ({ path, ...config }, backendUrl) => {
//     const url = `${backendUrl}${para}`
//   },
// })

enum Versions {
  v1 = "v1",
  v2 = "v2",
}

export const BASE_URL =
  process.env.REACT_APP_ENV === "dev"
    ? `https://ftl-tech-admin-api-stg1.sushivesla.net/api/${Versions.v1}`
    : `https://tech-admin-api.prod-1.foodtech-lab.ru/api/${Versions.v1}`

export const API_BASE_URL =
  process.env.REACT_APP_ENV === "dev"
    ? "https://ftl-tech-admin-api-stg1.sushivesla.net"
    : "https://tech-admin-api.prod-1.foodtech-lab.ru"

// const requestFx =

const getHeaders = () => {
  const headers: any = {
    "X-Device-Type": "WEB",
  }
  const token = getCookie("accessToken")
  if (token) {
    headers["X-Auth-Token"] = token
  }
  if (localStorage.getItem("deviceToken")) {
    headers["X-Device-Token"] = localStorage.getItem("deviceToken")
  } else {
    const deviceToken = nanoid()
    headers["X-Device-Token"] = deviceToken
  }
  return { common: { ...headers } }
}

export const api = axios.create({
  headers: getHeaders(),
  baseURL: BASE_URL,
  // transformResponse: (data) => {
  //   if (typeof data === "string") {
  //     try {
  //       data = JSON.parse(data)
  //       if (data.result) {
  //         data = data.result
  //       }
  //     } catch (e) {
  //       // noop
  //     }
  //   }
  // },
})

api.interceptors.request.use((request) => {
  const token = getCookie("accessToken")
  if (token) {
    request.headers.common["X-Auth-Token"] = token
  }
  return request
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (
      error.response.data.errors[0].details === "EXPIRED_TOKEN" ||
      error.response.data.errors[0].details === "NOT_EXIST" ||
      error.response.status === 401
    ) {
      const refreshToken = getCookie("refreshToken")
      if (refreshToken) {
        const refreshOnce = once(function () {
          axios
            .post(
              `${API_BASE_URL}/not-secure/api/v1/auth/refresh`,
              {
                refreshToken,
              },
              { headers: { ...api.defaults.headers.common } }
            )
            .then((result) => {
              const { accessToken, refreshToken } = result.data.result
              Cookies.set("accessToken", accessToken)
              Cookies.set("refreshToken", refreshToken)
              api.defaults.headers.common["X-Auth-Token"] = accessToken
              // TODO retry origin request
              window.location.reload()
              // return api.request(error.config)
            })
            .catch((error: any) => {
              Cookies.remove("accessToken")
              Cookies.remove("refreshToken")
              history.push("/")
            })
        })
        refreshOnce()
        // axios
        //   .post(
        //     `${API_BASE_URL}/not-secure/api/v1/auth/refresh`,
        //     {
        //       refreshToken,
        //     },
        //     { headers: { ...api.defaults.headers.common } }
        //   )
        //   .then((result) => {
        //     const { accessToken, refreshToken } = result.data.result
        //     Cookies.set("accessToken", accessToken)
        //     Cookies.set("refreshToken", refreshToken)
        //   })
        //   .catch((error: any) => {
        //     Cookies.remove("accessToken")
        //     Cookies.remove("refreshToken")
        //     history.push("/")
        //   })
      } else {
        Cookies.remove("accessToken")
        Cookies.remove("refreshToken")
        history.push("/")
      }
    }
  }
)

// type Citie
export const getCities = () => {
  return api.get("city/?sortDirection=asc&sortName=name&limit=50")
}

export const getAllCities = () => {
  return api.get("city/all?sortDirection=asc&sortName=name")
}

export const getCountries = () => {
  return api.get("country?sortDirection=asc&sortName=name")
}

export const getServiceZones = (
  offset: number = 0,
  limit?: number
): Promise<
  AxiosResponse<{
    result: {
      count: number
      items: [
        {
          id: string
          name: string
          dispatcherPhone: string
        }
      ]
    }
  }>
> => {
  return api.get(
    `serviceZone?sortDirection=asc&sortName=name&limit=${
      limit ? limit : 50
    }&offset=${offset}`
  )
}

export const getCouriers = (
  query = "",
  cityId = "",
  offset: number = 0,
  limit?: number
): Promise<
  AxiosResponse<{
    result: {
      count: number
      items: [
        {
          cityId: string
          countryId: string
          firstName: string
          secondName: string
          lastName: string
          serviceZoneId: string
          id: string
          phoneNumber: string
        }
      ]
    }
  }>
> => {
  const params = []
  if (query) params.push(`query=${query}`)
  if (cityId) params.push(`cityId=${cityId}`)
  return api.get(
    `courier?${params.join("&")}&limit=${limit ? limit : 10}&offset=${offset}`
  )
}

export const getCourierById = (
  id: string
): Promise<
  AxiosResponse<{
    result: {
      cityId: string
      countryId: string
      firstName: string
      secondName: string
      lastName: string
      serviceZoneId: string
      id: string
      phoneNumber: string
      externalSystems: {
        externalSystemSyncAccountId: string
        id: string
      }[]
    }
  }>
> => api.get(`courier/${id}`)

export const createCourier = (request: {
  cityId: string | null
  countryId: string | null
  serviceZoneId: string | null
  firstName: string
  middleName?: string
  lastName: string
  phoneNumber?: number
  externalSystems?: {
    externalSystemSyncAccountId?: string
    id?: string
  }[]
}) => {
  return api.post("courier", {
    // request: {
    cityId: request.cityId,
    countryId: request.countryId,
    serviceZoneId: request.serviceZoneId,
    firstName: request.firstName,
    secondName: request.middleName,
    lastName: request.lastName,
    phoneNumber: request.phoneNumber,
    externalSystems: request.externalSystems,
    // },
  })
}

export const editCourier = (request: {
  id: string
  cityId: string | null
  countryId: string | null
  serviceZoneId: string | null
  firstName: string
  middleName?: string
  lastName: string
  phoneNumber?: number
  externalSystems?: {
    externalSystemSyncAccountId?: string
    id?: string
  }[]
}) => {
  return api.patch("courier", {
    id: request.id,
    cityId: request.cityId,
    countryId: request.countryId,
    serviceZoneId: request.serviceZoneId,
    firstName: request.firstName,
    secondName: request.middleName,
    lastName: request.lastName,
    phoneNumber: request.phoneNumber,
    externalSystems: request.externalSystems,
  })
}

// export const getCouriersByServiceId = (
//   offset = 0,
//   query = "",
//   serviceAccountId?: string
// ): Promise<
//   AxiosResponse<{
//     result: { count: number; items: { fullName: string; id: string }[] }
//   }>
// > => {
//   return api.get(
//     `externalSystem/iiko/courier?serviceAccountId=${serviceAccountId}&offset=${offset}&query=${query}`
//   )
// }

export const getCouriersByServiceId = (
  // offset = 0,
  query = "",
  serviceAccountId?: string
): Promise<
  AxiosResponse<{
    result: { count: number; items: { fullName: string; id: string }[] }
  }>
> => {
  return api.get(
    `externalSystem/iiko/courier?serviceAccountId=${serviceAccountId}&limit=10&query=${query}`
  )
}

export const getIIKOCourierById = (
  id: string
): Promise<AxiosResponse<{ result: { fullName: string; id: string } }>> =>
  api.get(`externalSystem/iiko/courier/${id}`)
