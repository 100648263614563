import React from "react"
import { typography } from "../../shared/theme"
import styled, { css } from "styled-components"
import { IconButton, Typography, TextField, Box } from "@material-ui/core"
import { Close, SvgIconComponent } from "@material-ui/icons"

type Props = {
  status?:
    | "new"
    | "neutral"
    | "success"
    | "error"
    | "disabled"
    | "info"
    | "warning"
    | string
  children?: React.ReactNode
  isDisabled?: boolean
  // onClose?: () => void
  icon?: React.ReactNode
  style?: {}
}

export const TagTooltip = ({
  isDisabled,
  status = "neutral",
  icon,
  style,
  children,
}: Props) => {
  //   const classes = useStyles()
  return (
    <Container style={style} status={status}>
      {/* <Box> */}
      <Typography
        style={{ ...typography.unsupportedSemanticTags.listItemNavText }}
      >
        {children}
      </Typography>
      {icon}
    </Container>
  )
}

const styleMixin = (status: Props["status"]) => {
  switch (status) {
    case "new":
      return `background-color: #F0E6F7; color: #390F57;`
    case "neutral":
      return `background-color: rgba(3, 10, 22, 0.04); color: #52637A;`
    case "success":
      return "background-color: #DFFBE9; color: #006633;"
    case "error":
      return "background-color: #FFEAE6; color: #C02502;"
    case "disabled":
      return "background-color: rgba(3, 10, 22, 0.04); color: #B7BEC8;"
    case "info":
      return "background-color: #CCE1FF; color: #1458B8;"
    case "warning":
      return "background-color: #FFFAE6; color: #2D3D53;"
    default:
      return `background-color: #F0E6F7; color: #390F57;`
  }
}

// const styleMixin = css``

const Container = styled.div<{ status: Props["status"] }>`
  // ${typography.unsupportedSemanticTags.listItemText};
  border-radius: 4px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  ${(props) => styleMixin(props.status)};
`
