import React, { useMemo, useReducer } from "react"
import { FTLTable } from "../../components/ui/Table/FTLTable"
import { TagTooltip } from "../../components/ui/TagTooltip"
import { BreacrumbsAction } from "./index"
import { Typography, Box } from "@material-ui/core"
import { FTLBreadcrumbs } from "../../components/ui/FTLBreadcrumbs"
import { BreadcrumbType } from "./index"
import { Link } from "react-router-dom"
import { FTLSearchField } from "../../components/ui/SearchField"
import { FTLSelect } from "../../components/ui/FTLSelect"

type Props = {
  // breadcrumbs: { to: string; label: string }[]
  // dispatchBreadcrumbsAction: React.Dispatch<BreacrumbsAction>
  title: string
}

type BreadcrumbsState = BreadcrumbType[]

const breadcrumbsReducer = (
  state: typeof breadcrumbsInitialState,
  action: BreacrumbsAction
): BreadcrumbsState => {
  switch (action.type) {
    case "ADD_BREADCRUMB": {
      return [...state, action.breadcrumb]
    }
    case "REMOVE_BREADCRUMB": {
      // const itemIsExist = state.indexOf(action.breadcrumb)
      return [...state]
    }
    default:
      throw Error("unexpected breadcrumbs action")
  }
}

const breadcrumbsInitialState: BreadcrumbsState = [
  { to: "/profile", label: "SMS-сервисы" },
  { to: "/sms-service/codes", label: "Коды авторизации" },
]

export const SmsServicesCodesPage = ({ title }: Props) => {
  const [breadcrumbsItems, dispatchBreadcrumbsAction] = useReducer(
    breadcrumbsReducer,
    breadcrumbsInitialState
  )
  const statuses = [
    "new",
    "default",
    "success",
    "warning",
    "error",
    "disabled",
    "neutral",
  ]
  const columns = useMemo(() => {
    return [
      {
        Header: "Получатель",
        accessor: "receiver",
        width: 200,
        disableSortBy: true,
        // maxWidth: 200,
      },
      {
        Header: "Статус",
        accessor: "status",
        width: 170,
        // type: "tooltip",
        Cell: (props: any) => {
          return (
            <TagTooltip
              status={statuses[Math.floor(Math.random() * 7)]}
              style={{ width: "fit-content" }}
            >
              {props.cell.value}
            </TagTooltip>
          )
        },
        disableSortBy: true,
      },
      {
        Header: "Вход выполнен",
        accessor: "isLoggedIn",
        width: 170,
        disableSortBy: true,
      },
      {
        Header: "Платформа",
        accessor: "platform",
        width: 170,
        disableSortBy: true,
      },
      {
        Header: "Код",
        accessor: "code",
        align: "right",
        width: 90,
        disableSortBy: true,
      },
      {
        Header: "Время отправки",
        accessor: "date",
        align: "right",
        disableSortBy: true,
        // Cell: (props: any) => {
        //   return <span style={{ textAlign: "right" }}>{props.cell.value}</span>
        // },
      },
    ]
  }, [])
  const data = useMemo(() => {
    return [
      {
        receiver: "+7 (999) 999-99-99",
        status: "Новое",
        isLoggedIn: Math.ceil(Math.random() * 2) === 1 ? "Да" : "Нет",
        platform: "Android",
        code: 4243,
        date: "17:33 20.02.2020",
      },
      {
        receiver: "+7 (999) 999-99-99",
        status: "Отправка",
        isLoggedIn: Math.ceil(Math.random() * 2) === 1 ? "Да" : "Нет",
        platform: "Android",
        code: 4243,
        date: "17:33 20.02.2020",
      },
      {
        receiver: "+7 (999) 999-99-99",
        status: "Доставлено",
        isLoggedIn: Math.ceil(Math.random() * 2) === 1 ? "Да" : "Нет",
        platform: "Android",
        code: 4243,
        date: "17:33 20.02.2020",
      },
      {
        receiver: "+7 (999) 999-99-99",
        status: "Новое",
        isLoggedIn: Math.ceil(Math.random() * 2) === 1 ? "Да" : "Нет",
        platform: "Android",
        code: 4243,
        date: "17:33 20.02.2020",
      },
      {
        receiver: "+7 (999) 999-99-99",
        status: "Новое",
        isLoggedIn: Math.ceil(Math.random() * 2) === 1 ? "Да" : "Нет",
        platform: "Android",
        code: 4243,
        date: "17:33 20.02.2020",
      },
      {
        receiver: "+7 (999) 999-99-99",
        status: "Новое",
        isLoggedIn: Math.ceil(Math.random() * 2) === 1 ? "Да" : "Нет",
        platform: "Android",
        code: 4243,
        date: "17:33 20.02.2020",
      },
      {
        receiver: "+7 (999) 999-99-99",
        status: "Новое",
        isLoggedIn: Math.ceil(Math.random() * 2) === 1 ? "Да" : "Нет",
        platform: "Android",
        code: 4243,
        date: "17:33 20.02.2020",
      },
      {
        receiver: "+7 (999) 999-99-99",
        status: "Новое",
        isLoggedIn: Math.ceil(Math.random() * 2) === 1 ? "Да" : "Нет",
        platform: "Android",
        code: 4243,
        date: "17:33 20.02.2020",
      },
      {
        receiver: "+7 (999) 999-99-99",
        status: "Новое",
        isLoggedIn: Math.ceil(Math.random() * 2) === 1 ? "Да" : "Нет",
        platform: "Android",
        code: 4243,
        date: "17:33 20.02.2020",
      },
      {
        receiver: "+7 (999) 999-99-99",
        status: "Новое",
        isLoggedIn: Math.ceil(Math.random() * 2) === 1 ? "Да" : "Нет",
        platform: "Android",
        code: 4243,
        date: "17:33 20.02.2020",
      },
      {
        receiver: "+7 (999) 999-99-99",
        status: "Новое",
        isLoggedIn: Math.ceil(Math.random() * 2) === 1 ? "Да" : "Нет",
        platform: "Android",
        code: 4243,
        date: "17:33 20.02.2020",
      },
      {
        receiver: "+7 (999) 999-99-99",
        status: "Новое",
        isLoggedIn: Math.ceil(Math.random() * 2) === 1 ? "Да" : "Нет",
        platform: "Android",
        code: 4243,
        date: "17:33 20.02.2020",
      },
      {
        receiver: "+7 (999) 999-99-99",
        status: "Новое",
        isLoggedIn: Math.ceil(Math.random() * 2) === 1 ? "Да" : "Нет",
        platform: "Android",
        code: 4243,
        date: "17:33 20.02.2020",
      },
      {
        receiver: "+7 (999) 999-99-99",
        status: "Новое",
        isLoggedIn: Math.ceil(Math.random() * 2) === 1 ? "Да" : "Нет",
        platform: "Android",
        code: 4243,
        date: "17:33 20.02.2020",
      },
      {
        receiver: "+7 (999) 999-99-99",
        status: "Новое",
        isLoggedIn: Math.ceil(Math.random() * 2) === 1 ? "Да" : "Нет",
        platform: "Android",
        code: 4243,
        date: "17:33 20.02.2020",
      },
      {
        receiver: "+7 (999) 999-99-99",
        status: "Новое",
        isLoggedIn: Math.ceil(Math.random() * 2) === 1 ? "Да" : "Нет",
        platform: "Android",
        code: 4243,
        date: "17:33 20.02.2020",
      },
    ]
  }, [])

  return (
    <>
      <FTLBreadcrumbs items={breadcrumbsInitialState} />
      {/* <FTLBreadcrumbs>
        {breadcrumbsItems.map((item, index) => (
          <Link key={index} to={item.to}>
            {item.label}
          </Link>
        ))}
      </FTLBreadcrumbs> */}
      <Box mt={1}>
        <Typography variant="h1">{title}</Typography>
      </Box>
      <Box
        mt={6}
        display="grid"
        gridGap="16px"
        gridTemplateColumns="200px 160px 160px"
      >
        <FTLSearchField placeholder="Номер получателя" />
        <FTLSelect
          // style={{ height: "32px", marginTop: "0px" }}
          id="select-platform"
          options={[
            { label: "Android", value: "Android" },
            { label: "iOS", value: "iOS" },
          ]}
          isClearable
          size="small"
        />
        <FTLSelect
          id="select-service"
          options={[
            { label: "Service 1", value: "service1" },
            { label: "Service 2", value: "service2" },
          ]}
          isClearable
          size="small"
        />
      </Box>
      <Box mt={4}>
        <FTLTable
          pageCount={1}
          // fixedTableSizes={[200, 170, 170, 170, 90, 170]}
          columns={columns}
          data={data}
        />
      </Box>
    </>
  )
}
