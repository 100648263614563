import React from "react"

import styled from "styled-components"

type TableRowProps = React.HTMLProps<HTMLTableRowElement>

type Props = {
  children?: React.ReactNode
  isDisabled?: boolean
}

// export const FTLTableCell = React.forwardRef<
//   HTMLTableRowElement,
//   TableRowProps
// >((props, ref) => <TableCell ref={ref}>{props.children}</TableCell>)

export const FTLTableRow = styled.tr.attrs({ height: "40px" })<{
  isDisabled?: boolean
  isFocusable?: boolean
}>`
  box-shadow: 0 2px 0 -1px #ebedf0;
  pointer-events: ${(props) => (props.isDisabled ? "none" : "auto")};
  ${(props) =>
    props.isFocusable &&
    `&:hover {
    background-color: #f5f9ff;
  }
  &:focus {
    outline: 2px solid #66a6ff;
  }
  `}
`

export const FTLTableRowHead = styled(FTLTableRow)`
  box-shadow: 0 4px 0 -2px #ebedf0;
`
