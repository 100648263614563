import React, { useEffect, useState } from "react"
import { getCookie } from "../shared/common"
import { Redirect, Route } from "react-router-dom"

type Props = {
  path: string | string[]
  exact?: boolean
  children?: React.ReactNode
}

export const PrivateRoute = ({ path, exact, children }: Props) => {
  const [isAuthenticated, setIsAuthenticated] = useState<null | boolean>(null)

  useEffect(() => {
    const token = getCookie("accessToken")
    // TODO add condition if token has expirated. Should I parse JWT  directly?
    if (token) {
      setIsAuthenticated(true)
    } else {
      setIsAuthenticated(false)
    }
  }, [path])

  return typeof isAuthenticated === "boolean" ? (
    Boolean(isAuthenticated) ? (
      <Route exact={exact} path={path}>
        {children}
      </Route>
    ) : (
      <Redirect to="/" />
    )
  ) : null
}
