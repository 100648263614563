import React, { useState } from "react"
import { Box, Typography } from "@material-ui/core"
import { FTLTextField, FTLButton } from "../../components"
import { typography } from "../../shared/theme"
import { FTLSelect } from "../../components/ui/FTLSelect"

export const ContactDataTab = () => {
  const [phoneMasks, setPhoneMasks] = useState([{ label: "+7", value: "7" }])
  return (
    <Box mt={6}>
      <Box>
        <Typography variant="h3">Почта</Typography>
        <Box mt={2}>
          <Typography style={{ ...typography.unsupportedSemanticTags.p2 }}>
            Ваш текущий адрес почты
            <span style={{ fontWeight: "bold" }}>test@mail.com</span>
          </Typography>
        </Box>
      </Box>
      <Box mt={10}>
        <Typography variant="h3">Телефон</Typography>
        <Box
          mt={2}
          display="grid"
          gridGap="8px"
          gridTemplateColumns="80px 240px"
        >
          <FTLSelect options={phoneMasks} defaultValue={phoneMasks[0]} />
          <FTLTextField placeholder="Номер телефона"></FTLTextField>
        </Box>
        {/* <Box mt={6}>
        <FTLButton disabled>Сохранить</FTLButton>
      </Box> */}
      </Box>
    </Box>
  )
}
