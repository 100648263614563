import React, { useEffect, useState, useMemo } from "react"
import { useHistory, useParams } from "react-router-dom"
import {
  getCourierById,
  getCities,
  getCountries,
  getServiceZones,
  editCourier,
  getIIKOCourierById,
} from "../../api/common"
import { useFormik } from "formik"
import { CourierSchema, CourierState } from "./model"
import { Box } from "@material-ui/core"
import { FTLJsonLogger } from "../../components/ui/FTLLogger"
import { FTLTextField } from "../../components"
import { useLayout } from "../../shared/hooks/useLayout"
import { FTLToolbar } from "../../components/ui/FTLToolbar"
import { FTLSelect } from "../../components/ui/FTLSelect"
import { FTLSelectAsync } from "../../components/ui/FTLSelectAsync"
import { VirtualizedSelectNew } from "../../components/ui/VirtualizedSelectNew"
import NumberFormat from "react-number-format"
import { FTLSnackbar } from "../../components/ui/FTLSnackbar"

type Props = {
  id?: string
}

const initState: { id: string | null } & CourierState = {
  firstName: "",
  middleName: "",
  lastName: "",
  phoneNumber: null,

  countryId: null,
  cityId: null,

  serviceId: null,
  externalSystems: [
    // SV
    // { externalSystemSyncAccountId: "5efb102dc8acc7466a4e4e73" },
    // BB
    // { externalSystemSyncAccountId: "5f071a5840b3b689f8c013c8" },
  ],

  id: null,

  // accountSV_Id: null,
  // accountBB_Id: null,
}

function normalizeDataToSelect(
  data: { [P: string]: string }[],
  valueField: string,
  labelField: string
) {
  const formatData = data.map((item) => {
    item.value = item[valueField]
    item.label = item[labelField]
    return item
  })
  return formatData
}

export const EditCourierPage = ({ id }: Props) => {
  const [formIsSubmitted, setFormIsSubmitted] = useState(false)

  const page = useLayout({
    components: {
      Breadcrumbs: {
        items: [
          { label: "Курьеры", to: "/couriers" },
          { label: "Параметры курьера", to: "#" },
        ],
      },
      Header: {
        title: "Параметры курьера",
      },
    },
  })

  const formik = useFormik({
    initialValues: initState,
    validationSchema: CourierSchema,
    onSubmit: (values) => {
      const formattedPhoneNumber =
        typeof values.phoneNumber === "number"
          ? values.phoneNumber
          : parseInt(values.phoneNumber!.replace(/[\s\-\(\)\+]/g, ""), 10)
      editCourier({
        id: values.id!,
        cityId: values.cityId,
        countryId: values.countryId,
        firstName: values.firstName,
        lastName: values.lastName,
        serviceZoneId: values.serviceId,
        phoneNumber: formattedPhoneNumber,
        externalSystems: values.externalSystems,
        middleName: values.middleName,
      }).then(() => {
        setFormIsSubmitted(true)
        formik.setSubmitting(false)
      })
    },
  })

  const [countries, setCountries] = useState<any[]>([])
  const [selectedCountry, setSelectedCountry] = useState({
    value: "",
    label: "",
  })

  const [cities, setCities] = useState<any[]>([])
  const [selectedCity, setSelectedCity] = useState({ value: "", label: "" })

  const [serviceZones, setServiceZones] = useState<any[]>([])
  const [selectedServiceZone, setSelectedServiceZone] = useState({
    value: "",
    label: "",
  })

  const [externalSystemSVValue, setExternalSystemSVValue] = useState<null | {
    value: string
    label: string
  }>(null)
  const [externalSystemBBValue, setExternalSystemBBValue] = useState<null | {
    value: string
    label: string
  }>(null)

  const params = useParams() as { id: string }

  useEffect(() => {
    Promise.all([
      getCourierById(params.id),
      getCities(),
      getCountries(),
      getServiceZones(),
    ]).then(([couriers, cities, countries, serviceZones]) => {
      const {
        cityId,
        countryId,
        serviceZoneId,
        externalSystems,
        firstName,
        lastName,
        id,
        secondName,
        phoneNumber,
      } = couriers.data.result
      setCountries(
        normalizeDataToSelect(countries.data.result.items, "id", "name")
      )
      setCities(normalizeDataToSelect(cities.data.result.items, "id", "name"))
      setServiceZones(
        normalizeDataToSelect(serviceZones.data.result.items, "id", "name")
      )

      // TODO refactor
      const existedExternalSystems = externalSystems.filter(
        (system: any) => system.id && system.externalSystemSyncAccountId
      )

      existedExternalSystems.forEach((system: any) => {
        getIIKOCourierById(system.id).then((result) => {
          const courier = result.data.result
          const option = {
            value: courier.id,
            label: courier.fullName,
          }
          if (system.externalSystemSyncAccountId === "5f071a5840b3b689f8c013c8")
            setExternalSystemSVValue(option)
          else if (
            system.externalSystemSyncAccountId === "5f16a79fbdc34adcb186a748"
          )
            setExternalSystemBBValue(option)
        })
      })
      // Promise.all([])
      // Promise.all(externalSystemsDeferredPromises).then((result) => {
      //   console.log(result)
      // })
      // externalSystemsIds.forEach(id => {
      //   if(id === '5f071a5840b3b689f8c013c8') setExternalSystemSVValue({value: id})
      // })
      // if(externalSystems.)
      const formattedPhoneNumber =
        typeof phoneNumber === "string"
          ? parseInt(phoneNumber.replace(/[\s\-\+]/g, ""), 10)
          : phoneNumber

      formik.setValues({
        id,
        cityId,
        countryId,
        serviceId: serviceZoneId,
        externalSystems: existedExternalSystems,
        firstName,
        lastName,
        middleName: secondName || "",
        phoneNumber: formattedPhoneNumber,
      })
    })
  }, [])

  // useEffect(() => {
  //   const id = setTimeout(() => {
  //     setFormIsSubmitted(false)
  //   })
  //   return () => clearTimeout(id)
  // }, [formIsSubmitted])

  useEffect(() => {
    setSelectedCountry(
      countries.find((country) => country.id === formik.values.countryId) || {
        value: "",
        label: "",
      }
    )
  }, [countries, formik.values.countryId])

  useEffect(() => {
    setSelectedCity(
      cities.find((city) => city.id === formik.values.cityId) || {
        value: "",
        label: "",
      }
    )
  }, [cities, formik.values.cityId])

  useEffect(() => {
    setSelectedServiceZone(
      serviceZones.find(
        (serviceZone) => serviceZone.id === formik.values.serviceId
      ) || {
        value: "",
        label: "",
      }
    )
  }, [serviceZones, formik.values.serviceId])
  return (
    <>
      <Box marginBottom="auto">
        {page.Breadcrumbs}
        {page.Header}
        {process.env.NODE_ENV === "development" ? (
          <>
            <FTLJsonLogger label="Стейт формы" value={formik.values} />
            <FTLJsonLogger label="Ошибки формы" value={formik.errors} />
          </>
        ) : null}
        <form
          id="new-courier-form"
          onSubmit={(e) => {
            e.preventDefault()
            formik.handleSubmit()
            // setFormIsSubmitted(true)
          }}
        >
          <Box display="grid" mt={6} gridGap="24px" width="360px">
            <Box>
              <FTLTextField
                required
                name="lastName"
                label="Фамилия"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.errors.lastName &&
                  formik.submitCount > 0 &&
                  formik.touched.lastName
                    ? true
                    : false
                }
                onBlur={formik.handleBlur}
                // onChange={(e: any) => dispatch(setLastName(e.target.value))}
                fullWidth
              />
            </Box>
            <Box>
              <FTLTextField
                name="firstName"
                required
                label="Имя"
                value={formik.values.firstName}
                onBlur={formik.handleBlur}
                // onChange={(e: any) => dispatch(setFirstName(e.target.value))}
                onChange={formik.handleChange}
                fullWidth
              />
            </Box>
            <Box>
              <FTLTextField
                label="Отчество"
                name="middleName"
                value={formik.values.middleName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                fullWidth
              />
            </Box>

            <Box>
              <NumberFormat
                // format="+# (###) ###-##-##"
                format={(value) => {
                  const firstDigit = value[0]

                  const phone =
                    `${
                      firstDigit === "7"
                        ? "+7"
                        : firstDigit === "8"
                        ? firstDigit
                        : ""
                    }` +
                    " " +
                    `(${value.slice(1, 4)})` +
                    " " +
                    value.slice(4, 7) +
                    "-" +
                    value.slice(7, 9) +
                    "-" +
                    value.slice(9, 11)
                  return phone
                }}
                mask="_"
                customInput={FTLTextField}
                label="Телефон"
                required
                name="phoneNumber"
                value={formik.values.phoneNumber as number}
                placeholder="Номер телефона"
                onChange={formik.handleChange}
                fullWidth
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber}
            </Box>
            <Box>
              <FTLSelect
                required
                label="Страна"
                onBlur={formik.handleBlur}
                value={{
                  value: selectedCountry.value,
                  label: selectedCountry.label,
                }}
                // isError={
                //   formik.errors.countryId && formik.submitCount > 0
                //     ? true
                //     : false
                // }
                onChange={(option) => {
                  // FIX IT, see https://github.com/DefinitelyTyped/DefinitelyTyped/issues/32553
                  formik.setFieldValue(
                    "countryId",
                    (option as { value: string })?.value
                  )
                }}
                options={countries}
                placeholder="Укажите страну"
              />
            </Box>
            <Box>
              <FTLSelect
                required
                label="Город"
                options={cities}
                onBlur={formik.handleBlur}
                value={{ value: selectedCity.value, label: selectedCity.label }}
                isError={
                  formik.errors.cityId && formik.submitCount > 0 ? true : false
                }
                onChange={(option) => {
                  formik.setFieldValue(
                    "cityId",
                    (option as { value: string }).value
                  )
                }}
                placeholder="Укажите город"
              />
            </Box>
            <Box>
              <FTLSelect
                required
                label="Зона обслуживания"
                // isClearable
                value={{
                  value: selectedServiceZone.value,
                  label: selectedServiceZone.label,
                }}
                placeholder="Укажите зону обслуживания"
                options={serviceZones}
                onChange={(option, actionMeta) => {
                  formik.setFieldValue(
                    "serviceId",
                    (option as { value: string }).value
                  )
                  //   if (actionMeta.action === "select-option") {
                  //     formik.setFieldValue(
                  //       "serviceId",
                  //       (option as { value: string }).value
                  //     )
                  //   } else formik.setFieldValue("serviceId", null)
                  // setSelectedService(option as any)
                }}
                isError={
                  formik.errors.serviceId && formik.submitCount > 0
                    ? true
                    : false
                }
              />
            </Box>
            <Box>
              <VirtualizedSelectNew
                serviceId="5f071a5840b3b689f8c013c8"
                label="Аккаунт курьера в СушиВёсла"
                name="externalSystems[0]"
                isClearable
                value={externalSystemSVValue}
                onChange={(option: any, actionMeta) => {
                  if (
                    actionMeta.action === "set-value" ||
                    actionMeta.action === "select-option"
                  ) {
                    formik.setFieldValue("externalSystems[0]", {
                      externalSystemSyncAccountId: "5f071a5840b3b689f8c013c8",
                      id: option.value,
                    })
                    setExternalSystemSVValue(option)
                  } else if (actionMeta.action === "clear") {
                    formik.setFieldValue("externalSystems[0]", {}, false)
                    setExternalSystemSVValue(null)
                  }
                }}
              />
            </Box>
            <Box>
              <VirtualizedSelectNew
                serviceId="5f16a79fbdc34adcb186a748"
                label="Аккаунт курьера в БлинБерри"
                isClearable
                value={externalSystemBBValue}
                name="externalSystems[1]"
                onChange={(option: any, actionMeta) => {
                  if (
                    actionMeta.action === "set-value" ||
                    actionMeta.action === "select-option"
                  ) {
                    formik.setFieldValue("externalSystems[1]", {
                      externalSystemSyncAccountId: "5f16a79fbdc34adcb186a748",
                      id: option.value,
                    })
                    setExternalSystemBBValue(option)
                  } else if (actionMeta.action === "clear") {
                    formik.setFieldValue("externalSystems[1]", {}, false)
                    setExternalSystemBBValue(null)
                  }
                }}
              />
              {/* <FTLSelect
            label="Аккаунт курьера в БлинБерри"
            placeholder="Укажите аккаунт"
            name="externalSystems[1]"
            options={[
              { value: "5f16a79fbdc34adcb186a748", label: "BB test" },
            ]}
            // onChange={(item: any) => {
            //   dispatch(setAccountBB(item.id))
            // }}
            onChange={(option: any) => {
              formik.setFieldValue("externalSystems[1]", {
                externalSystemSyncAccountId: "5f071a5840b3b689f8c013c8",
                id: option.value,
              })
            }}
          /> */}
            </Box>
          </Box>
        </form>
        {/* <FTLSnackbar
          open={errorTooltip.open}
          message={errorTooltip.message as string}
          onClose={() => setErrorTooltip({ open: false, message: null })}
          hideDuration={3000}
          variant="error"
        />
        <FTLSnackbar
          open={formik.isSubmitting && !formik.isValid}
          hideDuration={1500}
          onClose={() => setFormIsSubmitted(false)}
          message="Пожалуйста, заполните обязательные поля"
          variant="error"
        />
      */}
        <FTLSnackbar
          open={formIsSubmitted}
          hideDuration={1500}
          variant="success"
          message="Данные сохранены"
        />
      </Box>
      <FTLToolbar
        onSaveBtnId="new-courier-form"
        position="sticky"
        async={formik.isSubmitting}
        onSaveMessage="Сохранить"
      />
    </>
  )
  //   return <h1>{id}</h1>
}
