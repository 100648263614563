import React, { useReducer, useState } from "react"
import { Container, Typography, Box } from "@material-ui/core"
import { typography } from "../shared/theme"
import { FTLButton } from "../components"
import { FTLDropdown } from "../components/ui/FTLDropdown"
// import { FTLSelect } from "../components/FTLSelect"
import { FTLToolbar } from "../components/ui/FTLToolbar"
import { FTLSelect } from "../components/ui/FTLSelect"

export const SettingsPage = () => {
  const smsServiceItems = [
    { label: "Не выбрано", value: "" },
    { label: "SMSC", value: "smsc" },
  ]
  const pushServiceItems = [
    { label: "Не выбрано", value: "" },
    { label: "Firebase", value: "firebase" },
  ]
  const [smsValue, setSmsValue] = useState("")
  const [pushValue, setPushValue] = useState("")

  return (
    <Container maxWidth="xs">
      <Box mt={4}>
        <Typography variant="h1">Параметры системы</Typography>
      </Box>

      <Box width="280px">
        <Box mt={6}>
          <FTLSelect
            // pla
            // size="small"

            label="Сервис для отправки смс-сообщений"
            options={smsServiceItems}
            // defaultValue={smsServiceItems[0]}
            // isClearable
            isSearchable={false}
            // isMulti
            // options={[{}]}
            // fullWidth
            // id="sms-service-select"
            // // defaultValue={smsServiceItems[0].value}
            // onChange={(e) => {
            //   setSmsValue(e.target.value as string)
            // }}
            // // value={smsValue}
            // items={smsServiceItems}
            // label="Сервис для отправки смс-сообщений"
          />
        </Box>
        <Box mt={6}>
          <FTLSelect
            options={pushServiceItems}
            label="Сервис для отправки push-сообщений"
            // isClearable

            defaultValue={pushServiceItems[0]}
            isSearchable={false}
          />
        </Box>
      </Box>
      {/* </Box> */}
      {/* <Box mt={6}>
        <FTLButton disabled={!smsValue && !pushValue}>Сохранить</FTLButton>
      </Box> */}
      <FTLToolbar />
    </Container>
  )
}
