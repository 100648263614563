import React, { useState, useRef, useMemo, useCallback } from "react"
import { FTLTablePaginationButton } from "./FTLTablePaginationButton"
import {
  Box,
  Typography,
  makeStyles,
  Popover,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core"
import { typography } from "../../../shared/theme"
import { KeyboardArrowDown } from "@material-ui/icons"

type Props = {
  pageIndex: number
  pageSize: number
  pageCount: number
  setPageSize: (value: number) => void
  onFetchPage?: (...args: any) => void
  gotoPage: (pageNumber: number) => void
}

const FTLTableSelect = ({ pageSize, setPageSize }: any) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const open = Boolean(anchorEl)
  return (
    <>
      <div
        className={classes.pageSizeContainer}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Typography
          style={{ ...typography.unsupportedSemanticTags.listItemText }}
        >
          На странице: {pageSize}
        </Typography>
        <KeyboardArrowDown color="secondary" className={classes.pageSizeIcon} />
      </div>
      <Popover
        id="table-selectPageSize"
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 100, vertical: "bottom" }}
      >
        <List>
          {[10, 20, 30, 40, 50].map((value) => (
            <ListItem
              button
              onClick={(e) => {
                setPageSize(value)
                setAnchorEl(null)
              }}
            >
              <ListItemText>{value}</ListItemText>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  )
}

const filterPages = (visiblePages: number[], totalPages: number) => {
  // const inserted = Array(totalPages)
  // for (let i = 1; i <= inserted.length; i++) {
  //   inserted.push(i)
  // }
  return visiblePages.filter((page) => page <= totalPages)
}

export const FTLTableFooter = ({
  onFetchPage,
  pageIndex,
  pageSize,
  pageCount,
  setPageSize,
  gotoPage = () => {},
}: Props) => {
  const classes = useStyles()
  const [activePageIndex, setActivePageIndex] = useState(0)

  const getVisiblePages = useCallback(
    (pageIndex: number) => {
      const pageNumber = pageIndex + 1
      return filterPages(
        [
          pageNumber,
          pageNumber + 1,
          pageNumber + 2,
          pageNumber + 3,
          pageNumber + 4,
        ],
        Math.ceil(pageCount / pageSize)
      )
    },
    [pageCount, pageSize]
  )

  const [visiblePages, setVisiblePages] = useState(getVisiblePages(pageIndex))

  return (
    <Box className={classes.root}>
      <Box display="flex" padding="0 8px" className={classes.container}>
        <Typography
          style={{ ...typography.unsupportedSemanticTags.listItemText }}
        >
          Всего: {pageCount}
        </Typography>
        <Box marginLeft={6}>
          <Typography
            style={{ ...typography.unsupportedSemanticTags.listItemText }}
          >
            <FTLTableSelect pageSize={pageSize} setPageSize={setPageSize} />
          </Typography>
          {/* <Typography */}
        </Box>
      </Box>
      {/* {pageIndex + 1 > 5 && ( */}
      <FTLTablePaginationButton
        onClick={() => gotoPage(1)}
        style={{ width: "86px", marginLeft: "auto" }}
      >
        В начало
      </FTLTablePaginationButton>
      {/* )} */}
      <Box display="flex" className={classes.paginationContainer}>
        {visiblePages.map((pageNumber, index) => (
          <FTLTablePaginationButton
            isActive={pageIndex === index}
            disabled={pageNumber > Math.ceil(pageCount / pageSize)}
            onClick={() => {
              gotoPage(pageNumber)
            }}
          >
            {pageNumber}
          </FTLTablePaginationButton>
        ))}
        {Math.floor(pageCount / pageSize) > 5 && (
          <FTLTablePaginationButton
            width="auto"
            style={{ width: "68px", marginRight: "0" }}
            onClick={() => {
              gotoPage(pageIndex + 2)
            }}
          >
            Далее
          </FTLTablePaginationButton>
        )}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "80px",
  },
  container: {
    alignItems: "center",
  },
  pageSizeContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  pageSizeIcon: {
    marginLeft: "10px",
  },
  paginationContainer: {
    "& > button": {
      width: "32px",
      margin: "4px",
    },
  },
  popover: {
    // right: 0,
  },
}))
