import React, { useMemo, useEffect, useState, useCallback, useRef } from "react"
import { Box, IconButton, Typography } from "@material-ui/core"
import { useLayout } from "../../shared/hooks/useLayout"
import { useHistory } from "react-router-dom"
import { FTLSearchField } from "../../components/ui/SearchField"
import { FTLSelect } from "../../components/ui/FTLSelect"
import { Edit } from "@material-ui/icons"
import { getCouriers, getCities, getAllCities } from "../../api/common"
import { FTLTable } from "../../components/ui/Table/FTLTable"
import { FTLCheckbox } from "../../components/ui/FTLCheckbox"

export const CouriersPage = () => {
  const history = useHistory()
  const [data, setData] = useState<any[]>([])
  const [query, setQuery] = useState("")
  const [skipPageReset, setSkipPageReset] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  // const [cities, setCities] = useState<{ [id: string]: string }>({})
  const citiesRef = useRef<{ [id: string]: string }>({})
  const [selectedCityId, setSelectedCityId] = useState<null | string>(null)
  const columns: any[] = useMemo(() => {
    return [
      {
        Header: "ФИО",
        accessor: "fullName",
        width: 200,
        disableSortBy: true,
        // maxWidth: 200,
      },
      {
        Header: "Город",
        accessor: "city",
        width: 170,
        disableSortBy: true,
      },
      {
        Header: "Телефон",
        accessor: "phone",
        width: 170,
        disableSortBy: true,
      },
      { Header: "", accessor: "action", width: 80, disableSortBy: true },
    ]
  }, [])

  const fetchCouriers = useCallback(
    (query, city, offset: number = 0, limit: number = 10) => {
      return getCouriers(query, city, offset, limit).then((result) => {
        // console.log(result.data.result)
        normalizeCouriers({
          couriers: result.data.result.items,
          count: result.data.result.count,
        })
      })
    },
    []
  )

  useEffect(() => {
    fetchCouriers(query, selectedCityId, 0, 10)
  }, [fetchCouriers, query, selectedCityId])

  const normalizeCouriers = useCallback(
    ({ couriers, count }) => {
      const normalizeDataOfTable = couriers.map((courier: any) => {
        const normalizeCourier: any = {}
        const fullName = `${courier.lastName || ""} ${
          courier.firstName || ""
        } ${courier.secondName || ""}`
        normalizeCourier.fullName = fullName
        normalizeCourier.city = citiesRef.current[courier.cityId]
        normalizeCourier.phone = courier.phoneNumber
        normalizeCourier.action = (
          <IconButton
            size="small"
            onClick={() => {
              history.push(`/couriers/${courier.id}`)
            }}
          >
            <Edit color="secondary" />
          </IconButton>
        )
        return normalizeCourier
      })
      setSkipPageReset(true)
      setPageCount(count)
      setData(normalizeDataOfTable)
      return normalizeDataOfTable
    },
    [history]
  )

  useEffect(() => {
    Promise.all([getAllCities(), getCouriers()]).then(([cities, couriers]) => {
      const normalizedCitiesByIds = cities?.data.result.items.reduce(
        (obj: any, currCity: any) => {
          const id = currCity.id
          obj[id] = currCity.name
          return obj
        },
        {}
      )
      const couriersWithCities = couriers?.data.result.items.map(
        (courier: any) => {
          return { ...courier, cityName: normalizedCitiesByIds[courier.cityId] }
        }
      )

      citiesRef.current = normalizedCitiesByIds

      normalizeCouriers({
        couriers: couriersWithCities || [],
        count: couriers?.data.result.count ?? 0,
      })
    })
  }, [normalizeCouriers])

  useEffect(() => {
    setSkipPageReset(false)
  }, [data])
  // setPageCount(Math.ceil(result.count / 10))
  // setData(normalizeDataToTable)

  const page = useLayout({
    components: {
      Header: {
        title: "Курьеры",
        button: {
          label: "Новый курьер",
          onClick: () => history.push("/couriers/new"),
        },
      },
      // Table: {
      //   columns,
      //   data,
      // },
    },
  })

  return (
    <Box mt={4}>
      <Box pl={2}>
        <Box>{page.Header}</Box>

        <Box display="flex" mt={6}>
          <Box width="200px">
            <FTLSearchField
              onChange={(value) => {
                setQuery(value)
              }}
              placeholder="ФИО или телефон"
            />
          </Box>
          <Box ml={4} width="160px">
            <FTLSelect
              isClearable
              options={Object.entries(citiesRef.current).map((city) => ({
                value: city[0],
                label: city[1],
              }))}
              onChange={(option) => {
                // @ts-ignore
                setSelectedCityId(option?.value ?? "")
              }}
              size="small"
              placeholder="Город"
            />
          </Box>
        </Box>
      </Box>

      {data.length > 0 ? (
        <FTLTable
          data={data}
          columns={columns}
          pageCount={pageCount}
          onFetchData={(offset, limit) => {
            fetchCouriers(query, selectedCityId, offset, limit)
          }}
          skipPageReset={skipPageReset}
        />
      ) : (
        <Box
          mt={6}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="50vh"
        >
          <Typography variant="h2" color="secondary">
            Нет данных для отображения
          </Typography>
        </Box>
      )}
    </Box>
  )
}
