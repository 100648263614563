import React from "react"
import { Button, ButtonProps, makeStyles } from "@material-ui/core"
import { typography } from "../../shared/theme"

type Props = {
  async?: boolean
} & ButtonProps

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "4px 12px",
    minHeight: "32px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover, &:active": {
      boxShadow: "none !important",
    },
    "&:hover": {
      backgroundColor: "#1A79FF",
    },
    "&:active": {
      backgroundColor: "#1458B8",
    },
  },
  primary: {
    "& $label": {
      fontWeight: 600,
    },
  },
  label: { fontSize: "14px", lineHeight: "20px", letterSpacing: "-0.09px" },
  large: {
    minHeight: "40px",
    padding: "10px 22px",
  },

  focus: {
    // border: "2px solid #66A6FF",
    boxShadow: "0 0 0 2px #66A6FF !important",
  },
  secondary: {
    backgroundColor: "rgba(3, 10, 22, 0.04)",
    "&:hover": { backgroundColor: "rgba(3, 11, 22, 0.08);" },
    "& $label": {
      ...typography.unsupportedSemanticTags.buttonTextSecondary,
      color: theme.palette.text.secondary,
    },
  },
  disabled: {
    backgroundColor: "rgba(3, 10, 22, 0.04) !important",
    "& $label": {
      color: theme.palette.text.disable,
    },
  },
  textPrimary: {},
  textSecondary: {
    // ...typography.unsupportedSemanticTags.buttonTextSecondary,
  },
  tertiary: {
    color: theme.palette.text.tertiary,
    "&:hover": { backgroundColor: "rgba(3, 11, 22, 0.08);" },
  },
  async: {
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    gridGap: "3px",
    "& .dot": {
      animation: "$dots 1.5s infinite ease-in-out",
      backgroundColor: "#fff",
      borderRadius: "10px",
      display: "inline-block",
      overflow: "hidden",
      height: "6px",
      width: "6px",
      "&:nth-child(2)": {
        animationDelay: ".5s",
      },

      "&:nth-child(3)": {
        animationDelay: "1s",
      },
    },
  },
  "@keyframes dots": {
    "0%": {
      opacity: 0.4,
      transform: "scale(1, 1)",
    },

    "50%": {
      opacity: 1,
      transform: "scale(1.2, 1.2)",
    },
    "100%": {
      opacity: 0.4,
      transform: "scale(1, 1)",
    },
  },
}))

export const FTLButton = ({
  variant = "contained",
  async,
  children,
  ...otherProps
}: Props) => {
  const classes = useStyles()

  return (
    <Button
      {...otherProps}
      variant={variant}
      disableRipple
      // TouchRippleProps={{ classes: { root: classes.ripple } }}
      classes={{
        root: classes.root,
        sizeLarge: classes.large,
        focusVisible: classes.focus,
        containedSecondary: classes.secondary,
        textSecondary: classes.textSecondary,
        containedPrimary: classes.primary,
        text: classes.tertiary,
        disabled: classes.disabled,

        label: classes.label,
      }}
    >
      {async ? (
        <div className={classes.async}>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      ) : (
        children
      )}
    </Button>
  )
}
