import React from "react"
import { Box, Typography } from "@material-ui/core"
import { FTLButton } from "./FTLButton"

type Props = {
  title?: string
  button?: {
    label: string
    onClick: () => void
  }
}

export const FTLHeader = ({ title, button }: Props) => {
  // const  = button
  return (
    <Box display="flex" justifyContent="space-between">
      <Typography variant="h1">{title}</Typography>
      {button && (
        <FTLButton onClick={button.onClick} color="primary">
          {button.label}
        </FTLButton>
      )}
    </Box>
  )
}
