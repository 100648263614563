import React from "react"
import { AuthProvider } from "auth-module-front"
import CssBaseline from "@material-ui/core/CssBaseline"
import { ThemeProvider } from "@material-ui/core"
import { theme } from "./shared/theme"
import { Router, Route, Switch } from "react-router-dom"

import { createBrowserHistory } from "history"

import { Routes } from "./Routes"

import { API_BASE_URL } from "./api/common"

export const history = createBrowserHistory()
const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider
        baseUrl={`${API_BASE_URL}/not-secure/api/v1/auth`}
        endpoints={{
          signIn: "/login/email",
          signOut: "/logout",
          refresh: "/refresh",
        }}
      >
        <Router history={history}>
          <Routes />
        </Router>
      </AuthProvider>
    </ThemeProvider>
  )
}

export default App
