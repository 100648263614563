import { createMuiTheme } from "@material-ui/core"
import * as colors from "./constants/colors"

export const typography = {
  supportedTags: {
    h1: {
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "32px",
      letterSpacing: "-0.47px",
    },
    h2: {
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "28px",
      letterSpacing: "-0.33px",
    },
    h3: {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "-0.18px",
    },
    h4: {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "-0.09px",
    },
    h5: {
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.01px",
    },
    caption: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.02px",
    },
  },
  unsupportedSemanticTags: {
    p1: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "-0.18px",
    },
    p1Medium: {
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "-0.18px",
    },
    p1Bold: {
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "-0.18px",
    },
    p2: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "-0.09px",
    },
    p2Medium: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "-0.09px",
    },
    p2Bold: {
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "-0.09px",
    },
    p3: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "-0.09px",
    },
    listItemText: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "-0.09px",
    },
    listItemNavText: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "-0.09px",
    },
    inputText: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "-0.09px",
    },
    buttonTextPrimary: {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "-0.09px",
    },
    buttonTextSecondary: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "-0.09px",
    },
    linkPrimary: {},
    linkSecondary: {},
    buttonTextBreadcrumbs: {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "-0.09px",
    },
    lawExplanations: {
      fontWeight: 400,
      letterSpacing: "0.05px",
      fontSize: "11px",
      lineHeight: "12px",
    },
    tooltip: {
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.02px",
    },
  },
}

export const palette = {
  primary: { main: "#136DEC" },
  secondary: {
    main: "#52637A",
  },
  text: {
    primary: "#132339",
    secondary: "#52637A",
    tertiary: "#627084",
    placeholder: "#6C7889",
    breadcrumbs: "#7C8A9C",
    disable: "#B7BEC8",
    error: "#E6360F",
    onColor: "#fff",
    borderColor: "#66A6FF",
    hover: "#EBEDF0",
  },
  icon: {
    secondary: "#52637A",
  },
  border: {
    textField: "#C4CBD4",
  },
  link: {
    secondary: colors.N400,
  },
  tabs: {
    selected: "#136DEC",
    enabled: "#52637A",
  },
}

export const theme = createMuiTheme({
  spacing: 4,
  breakpoints: {
    values: {
      md: 920,
    },
  },
  palette,
  typography: {
    ...typography.supportedTags,
    fontFamily: "Inter, Roboto, Arial",
  },
  overrides: {
    // MuiSelect: {
    //   select: {
    //     "&:focus": {
    //       border: "2px solid #66A6FF",
    //     },
    //   },
    // },
    MuiSvgIcon: {
      colorSecondary: {
        color: "#52637a",
      },
      fontSizeSmall: {
        width: "20px",
        height: "20px",
      },
    },
    MuiIcon: {
      colorSecondary: {
        color: "#52637a",
      },
    },
    MuiIconButton: {
      sizeSmall: {
        padding: "4px",
      },
    },
    MuiCssBaseline: {
      "@global": {
        a: {
          textDecoration: "none",
          color: "inherit",
        },
        body: {
          backgroundColor: "#fff",
        },
      },
    },
  },
})
