import React, { useState } from "react"
import { Container, Typography, Box } from "@material-ui/core"
import { typography } from "../../shared/theme"
import { FTLButton, FTLTextField } from "../../components"

export const ConfigurationTab = () => {
  const [state, setState] = useState({
    active: false,
  }) // to reducer
  return (
    <Container maxWidth="md">
      {/* TODO вынести табные страницы в компоненты  */}
      {state.active ? (
        <Box mt={4} mb={4} width="360px">
          <Box>
            <FTLTextField fullWidth value="default value" label="Логин" />
          </Box>
          <Box mt={6}>
            <FTLTextField fullWidth value="default value" label="Пароль" />
          </Box>
          <Box mt={6}>
            <FTLTextField fullWidth value="default value" label="Отправитель" />
          </Box>
          <Box mt={6}>
            <FTLTextField fullWidth value="default value" label="Сообщение" />
          </Box>
          <Box mt={6} width="80px">
            <FTLTextField
              fullWidth
              // style={{ width: "80px" }}
              value={3}
              label="Лимит сообщений на клиента в день"
            />
          </Box>
          <Box mt={6} width="80px">
            <FTLTextField
              fullWidth
              value={10}
              label="Лимит частоты запросов в день"
            />
          </Box>
          <Box mt={6} width="80px">
            <FTLTextField
              fullWidth
              value={3}
              label="Лимит частоты запросов, мин."
            />
          </Box>
          <Box mt={6} width="80px">
            <FTLTextField
              fullWidth
              value={3}
              label="Лимит частоты запросов с одного ip в час"
            />
          </Box>
          <Box mt={6} width="80px">
            <FTLTextField
              fullWidth
              value={3}
              label="Лимит частоты запросов с устройства в час"
            />
          </Box>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Box mt={24}>
            <Typography variant="h3">Нет данных для отображения</Typography>
          </Box>
          <Box mt={3}>
            <Typography style={{ ...typography.unsupportedSemanticTags.p2 }}>
              Настройте основные параметры сервиса, чтобы полуить данные
              конфигурации
            </Typography>
          </Box>
          <Box mt={6}>
            <FTLButton
              color="primary"
              onClick={() => setState({ active: true })}
            >
              Настроить
            </FTLButton>
          </Box>
        </Box>
      )}
    </Container>
  )
}
