import React from "react"
import { CheckboxProps, Checkbox, makeStyles } from "@material-ui/core"

type Props = CheckboxProps

export const FTLCheckbox = ({ ...props }: Props) => {
  const classes = useStyles()
  return (
    <Checkbox
      classes={{
        root: classes.root,
      }}
      color="primary"
      {...props}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "20px",
    "& > span >  svg": {
      width: "14px",
      height: "14pxpx",
    },
  },
}))
