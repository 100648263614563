import React, { useState, ChangeEvent } from "react"
import { Typography, Tab, Tabs, Box } from "@material-ui/core"
import { MainSettingsTab } from "./MainSettingsTab"
import { ConfigurationTab } from "./ConfigurationTab"
import { FTLTab, FTLTabs } from "../../components/ui/FTLTabs"
import { FTLBreadcrumbs } from "../../components/ui/FTLBreadcrumbs"
import { BreadcrumbType } from "../../pages/SmsServices"
import { FTLToolbar } from "../../components/ui/FTLToolbar"
// import { FTLTabs, FTLTab } from "../../components/FTLTabs"

type BreadcrumbsState = BreadcrumbType[]

const breadcrumbsInitialState: BreadcrumbsState = [
  { to: "/sms-services/params", label: "SMS-сервисы" },
  { to: "/sms-services/params", label: "Параметры SMS-сервисов" },
  { to: "/sms-services/new", label: "Новый сервис" },
]

export const NewSmsService = () => {
  const [tabIndex, setTabIndex] = useState(0)
  // const [breadcrumbsItems, dispatchBreadcrumbsAction] = useReducer(
  //   breadcrumbsReducer,
  //   breadcrumbsInitialState
  // )
  return (
    <>
      <FTLBreadcrumbs items={breadcrumbsInitialState} />
      <Box mt={1}>
        <Typography variant="h1">Новый внешний сервис</Typography>
      </Box>
      {/* <FTLTabs>
        <FTLTab />
        <FTLTab />
        <FTLTab />
      </FTLTabs> */}
      {/* <Tabs value={tabIndex} onChange={(e, newValue) => setTabIndex(newValue)}>
        <Tab label="Основные" />
        <Tab label="Конфигурация" />
      </Tabs> */}
      <Box mt={6} height="calc(100% - 56px - 24px - 84px)">
        <FTLTabs
          value={tabIndex}
          onChange={(e, newValue) => {
            setTabIndex(newValue)
          }}
        >
          <FTLTab label="Основные" />
          <FTLTab label="Конфигурация" />
        </FTLTabs>
        {tabIndex === 0 && <MainSettingsTab />}
        {tabIndex === 1 && <ConfigurationTab />}
      </Box>
      <FTLToolbar
        currentTabIndex={tabIndex}
        length={1}
        // position="relative"
        // position={tabIndex === 1 ? "relative" : "absolute"}
        goToNextTab={() => setTabIndex(tabIndex + 1)}
        goToPrevTab={() => setTabIndex(tabIndex - 1)}
      />
    </>
  )
}
