import React, { useState } from "react"
import { Route, Switch } from "react-router-dom"
import { BaseLayout } from "./components/BaseLayout"
import { PermissionDeniedPage } from "./pages/PermissionDenied"
import { SmsServicesPage } from "./pages/SmsServices"
import { NewSmsService } from "./layouts/SmsServices/NewService"
import { PushServicesPage } from "./pages/PushServices"
import { UsersPage } from "./pages/UsersPage"
import { NewUserPage } from "./pages/UsersPage/NewUser"
import { TimeZonesPage } from "./pages/TimeZones/TimeZones"
import { TimeZonesNewPage } from "./pages/TimeZones/New"
import { CouriersPage } from "./pages/Couriers/Couriers"
import { NewCourierPage } from "./pages/Couriers/New"
import { SettingsPage } from "./pages/Settings"
import { AuthPage } from "./pages/Auth"
import { AccessErrorPage } from "./pages/AccessError"
import { ProfilePage } from "./pages/Profile"
import { FTLSelect } from "./components/ui/FTLSelect"
import { Box } from "@material-ui/core"
import { VirtualizedSelect } from "./components/ui/VirtualizedSelect"
import { FTLSearchField } from "./components/ui/SearchField"
import { PrivateRoute } from "./components/PrivateRouter"
import { VirtualizedSelectNew } from "./components/ui/VirtualizedSelectNew"
import { EditCourierPage } from "./pages/Couriers/Edit"

export const Routes = () => {
  const [value, setValue] = useState("")
  return (
    <>
      <Switch>
        <Route exact path="/">
          <AuthPage />
        </Route>
        <PrivateRoute exact path="/profile">
          <BaseLayout>
            <ProfilePage />
          </BaseLayout>
        </PrivateRoute>

        <PrivateRoute path="/temp">
          <Box width="580px" height="100vh" margin="auto">
            <VirtualizedSelectNew
              isSearchable
              serviceId="5f071a5840b3b689f8c013c8"
            />
            {/* <VirtualizedSelect
            isSearchable
            serviceId="5f071a5840b3b689f8c013c8"
          />
          <br /> */}
            {/* <VirtualizedSelect2
            serviceId="5f071a5840b3b689f8c013c8"
            placeholder="Выбрать..."
          /> */}
            {/* <FTLSelect isSearchable options={[{ value: "1", label: "2" }]} /> */}
          </Box>
        </PrivateRoute>

        <PrivateRoute exact path={["/sms-services", "/sms-services/params"]}>
          <BaseLayout>
            <SmsServicesPage page="params" />
          </BaseLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/sms-services/codes">
          <BaseLayout
            style={{
              maxWidth: "initial",
              margin: "0 40px",
            }}
          >
            <SmsServicesPage page="codes" />
          </BaseLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/sms-services/new">
          <BaseLayout>
            <NewSmsService />
          </BaseLayout>
        </PrivateRoute>
        {/* <PrivateRoute exact path="/sms-services/new">
  <BaseLayout>
    <NewSmsServicePage />
  </BaseLayout>
</PrivateRoute> */}

        <PrivateRoute exact path="/push-services">
          <BaseLayout>
            <PushServicesPage />
          </BaseLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/push-services/new">
          <BaseLayout>
            <PushServicesPage />
          </BaseLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/users">
          <BaseLayout style={{ maxWidth: "initial", margin: "0 40px" }}>
            <UsersPage />
          </BaseLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/users/new">
          <BaseLayout>
            <NewUserPage />
          </BaseLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/time-zones">
          <BaseLayout>
            <TimeZonesPage />
          </BaseLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/time-zones/new">
          <BaseLayout>
            <TimeZonesNewPage />
          </BaseLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/couriers/">
          <BaseLayout>
            <CouriersPage />
          </BaseLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/couriers/new">
          <BaseLayout style={{ display: "flex", flexDirection: "column" }}>
            <NewCourierPage />
          </BaseLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/couriers/:id">
          <BaseLayout style={{ display: "flex", flexDirection: "column" }}>
            <EditCourierPage />
          </BaseLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/settings">
          <BaseLayout>
            <SettingsPage />
          </BaseLayout>
        </PrivateRoute>

        {/* temp PrivateRoutes */}
        <PrivateRoute path="/access-error">
          <BaseLayout>
            <AccessErrorPage />
          </BaseLayout>
        </PrivateRoute>
        <PrivateRoute path="/permission-denied">
          <BaseLayout>
            <PermissionDeniedPage />
          </BaseLayout>
        </PrivateRoute>
      </Switch>
    </>
  )
}
