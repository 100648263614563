import React, { useState, useEffect, useRef } from "react"
import {
  Tabs,
  makeStyles,
  Tab,
  TabsTypeMap,
  ButtonBaseTypeMap,
  ExtendButtonBase,
  TabTypeMap,
  TabProps,
  TabsProps,
  Divider,
  Box,
} from "@material-ui/core"
import { OverridableComponent } from "@material-ui/core/OverridableComponent"
import { palette, typography } from "../../shared/theme"

type TabsTypeProps = {
  // tabIndex: number
  onChange: (e: React.ChangeEvent<{}>, value: number) => void
  value: number // value is tabIndex
} & Omit<TabsProps, "value" | "tabIndex" | "onChange">

export const FTLTabs = ({ value, ...props }: TabsTypeProps) => {
  const tabIndicatorRef = useRef<null | HTMLDivElement>(null)
  // const [tabIndex, setTabIndex] = useState(0)
  const [tabIndicatorWidth, setTabIndicatorWidth] = useState(0)
  const classes = useStyles()
  useEffect(() => {
    const tabsContainer =
      tabIndicatorRef.current?.firstElementChild?.firstElementChild
    const tab = tabsContainer?.children[value]
    if (tab?.firstElementChild) {
      setTabIndicatorWidth(tab.firstElementChild.clientWidth)
    }
  }, [tabIndicatorRef, value])

  return (
    <Box position="relative">
      <Tabs
        classes={{
          root: classes.tabsRoot,
          indicator: classes.tabIndicator,
          flexContainer: classes.tabsContainer,
        }}
        value={value}
        indicatorColor="primary"
        innerRef={tabIndicatorRef}
        // onChange={onChange}
        // onChange={(e, newValue) => setTabIndex(newValue)}
        TabIndicatorProps={{
          style: tabIndicatorWidth
            ? {
                zIndex: 1,
                clip: `rect(0px, ${
                  value > 0
                    ? `${tabIndicatorWidth + 12}px`
                    : `${tabIndicatorWidth}px`
                }, 2px, ${value > 0 ? "12px" : "0"})`,
              }
            : { zIndex: 1 },
        }}
        {...props}
      ></Tabs>
      <Divider absolute style={{ height: "2px", backgroundColor: "#E3E5E8" }} />
    </Box>
  )
}

export const FTLTab = ({ ...props }: TabProps) => {
  const classes = useStyles()
  return (
    <Tab
      classes={{
        root: classes.tab,
        selected: classes.selectedTab,
        wrapper: classes.tabText,
      }}
      {...props}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  formContainer: {
    // marginTop: `${theme.spacing() * 6}px`,
  },
  tabsRoot: { minHeight: "32px" },
  tabsContainer: {
    "& button": {
      whiteSpace: "nowrap",
      "&:first-child": { padding: "0 12px 0 0" },
    },
  },
  tab: {
    color: palette.tabs.enabled,
    minHeight: "32px",
    padding: "6px 12px",
  },
  tabText: {
    textTransform: "none",

    ...typography.unsupportedSemanticTags.buttonTextSecondary,
  },
  selectedTab: {
    color: theme.palette.primary.main,
  },
  tabIndicator: {
    borderRadius: "2px",
  },
}))
