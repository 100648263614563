import React, { useReducer } from "react"
import { Box, Avatar } from "@material-ui/core"
import { FTLTextField, FTLButton } from "../../components"
import Placeholder from "../../assets/img/icons/ic_avatar_placeholder.svg"

const initState = {
  lastName: "",
  firstName: "",
  middleName: "",
}

type Action =
  | {
      type: "CHANGE_FIRST_NAME"
      payload: {
        value: string
      }
    }
  | {
      type: "CHANGE_MIDDLE_NAME"
      payload: {
        value: string
      }
    }
  | {
      type: "CHANGE_LAST_NAME"
      payload: {
        value: string
      }
    }
  | {
      type: "RESET_STATE"
    }

const reducer = (state: typeof initState, action: Action) => {
  switch (action.type) {
    case "CHANGE_FIRST_NAME":
      return { ...state, firstName: action.payload.value }
    case "CHANGE_MIDDLE_NAME":
      return { ...state, middleName: action.payload.value }
    case "CHANGE_LAST_NAME":
      return { ...state, lastName: action.payload.value }
    case "RESET_STATE":
      return { firstName: "", middleName: "", lastName: "" }
    default:
      return state
  }
}

export const PersonalDataTab = () => {
  const [{ lastName, firstName, middleName }, dispatch] = useReducer(
    reducer,
    initState
  )

  return (
    <Box
      // component="form"
      mt={6}
      // className={classes.formContainer}
      display="flex"
    >
      <Box width={360} display="grid" gridGap={24}>
        <FTLTextField
          required
          label="Фамилия"
          name="lastName"
          value={lastName}
          onChange={(e) => {
            dispatch({
              type: "CHANGE_LAST_NAME",
              payload: { value: e.target.value },
            })
          }}
        />
        <FTLTextField
          required
          name="firstName"
          label="Имя"
          value={firstName}
          onChange={(e) =>
            dispatch({
              type: "CHANGE_FIRST_NAME",
              payload: { value: e.target.value },
            })
          }
        />
        <FTLTextField
          label="Отчество"
          name="middleName"
          value={middleName}
          onChange={(e) =>
            dispatch({
              type: "CHANGE_MIDDLE_NAME",
              payload: { value: e.target.value },
            })
          }
        />
      </Box>
      <Box ml="auto">
        <Avatar style={{ width: "96px", height: "96px" }}>
          <img src={Placeholder} alt="profile placeholder" />
        </Avatar>
        <FTLButton
          color="secondary"
          // variant="contained"
          style={{ marginTop: "8px" }}
        >
          Загрузить
        </FTLButton>
      </Box>
    </Box>
  )
}
